/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { useSelector } from 'react-redux'
// import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import './NearProducts.css'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
// import { getNearProducts } from '@services/api.service'
// import { Link } from 'react-router-dom'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import ItemCardNew from '../../zearch/components/ItemCardNew'
import {
    getfeaturedProducts,
} from '@services/api.service'
/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function FeaturedProductsNew({
    setIsShowRecommended,
    searchHistory
}) {
    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)
    const isMobile = useSelector(state => state.content.isMobile)
    const [selectedId, setSelectedId] = React.useState(3)

    const { addToCart } = ShoppingCartHook()
    const productListRef = React.useRef(null)

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getfeaturedProducts'],
        queryFn: () => getfeaturedProducts({
            lat: location?.lat,
            lng: location?.lng,
        }),
    })

    if (isPending)
        return <LoadingSection text='Loading Products' height='200px' />

    const products = data?.data

    // let titleName = 'Hot deals near you'
    let titleName = 'featured products'

    const onMoveCard = (id) => {
        setSelectedId(id)
    }

    return (
        <section className="w-full pb-[100px] mb-[50px] border-y-2 smsize:my-1 smsize:py-2">
            <div className='w-full text-center uppercase text-[30px] py-16 smsize:text-[23px] smsize:py-2'>
                <h2 className='m-0 p-0'>{titleName}</h2>
            </div>
            <div className="m-auto w-[1024px] flex gap-3 text-left items-center justify-center smsize:justify-start smsize:w-full smsize:overflow-y-auto" ref={productListRef}>
                {products?.slice(0, 7)?.map((item, index) => {
                    return <ItemCardNew isMobile={isMobile} index={index} selectedId={selectedId} key={item?._id} userinfo={userinfo} item={item} handleAddToCart={addToCart} onMoveCard={onMoveCard}/>
                })}
            </div>
        </section>
    )
}
