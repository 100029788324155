import React from 'react'
import { FiPhoneCall } from 'react-icons/fi'
import { GoHome } from 'react-icons/go'
import { IoTimeOutline } from 'react-icons/io5'
import { CiHeart } from 'react-icons/ci'
import { MdOutlineMessage } from 'react-icons/md'
import { PiShareFatLight } from 'react-icons/pi'
import { BsPatchCheckFill, BsCheck } from 'react-icons/bs'
// import { IoIosSearch } from 'react-icons/io'
// import StoreFilter from './StoreFilter'
// import DealProducts from './components/DealProducts'
// import NewItemsProducts from './components/NewItemsProducts'
// import AllProducts from './components/AllProducts'
import StoreAbout from './StoreAbout'
import { useSelector, useDispatch } from 'react-redux'
import StoreMain from './StoreMain'
import StoreMedia from './StoreMedia'
import { useQuery } from '@tanstack/react-query'
import { getStoreProfile } from '@services/api.service'
import LoadingSection from '@components/LoadingSection'
import copy from 'copy-to-clipboard'
import { message, Drawer } from 'antd'
import MessageForm from './components/MessageForm'
import {
    setStoreProfileFilterChanged
} from '@reducers/contentReducer'

const tabs = ['shop', 'about', 'media']

export default function StoreProfile({
    storeId
}) {
    const dispatch = useDispatch()
    const isMobile = useSelector(state => state.content.isMobile)
    const userinfo = useSelector(state => state.user.userinfo)
    const location = useSelector(state => state.user.location)
    const [isOpenMessage, setIsOpenMessage] = React.useState(false)
    const [tab, setTab] = React.useState('shop')

    const {
        isPending,
        error,
        data: business,
    } = useQuery({
        queryKey: ['storeProfile', storeId, location?.lat, location?.lng],
        queryFn: () => getStoreProfile(storeId, {
            lat: location?.lat,
            lng: location?.lng,
        }),
    })

    if (isPending) return <LoadingSection text='Loading Store Profile' height={'200px'} />
    if (error) {
        console.error('Error loading store profile', error)
        return 'Loading Store Profile Error'
    }

    console.log('business', business)
    // get today's openhours from business.openHours
    // get the today shortname, like Mon, Tue, Wed, etc
    function getTodayShortDayName(locale = 'en-CA') {
        const formatter = new Intl.DateTimeFormat(locale, { weekday: 'short' })
        return formatter.format(new Date())
    }
    console.log('Today is ', getTodayShortDayName())
    const openHour = business.openHours.find(h => h.dayStart === getTodayShortDayName())
    console.log('openHour', openHour)

    let currentStatus = <span className='text-red-500'>Closed</span>
    if (openHour) {
        const openAt = parseInt(openHour.openAt.split(':')[0])
        const closeAt = parseInt(openHour.closeAt.split(':')[0])
        const currentHour = new Date().getHours()
        if (currentHour >= openAt && currentHour < closeAt) {
            currentStatus = <span className='text-green-500'>Opened</span>
        }
    }

    return (
        <div className='w-full'>
            <Drawer
                title="Send a message"
                placement="right"
                closable={true}
                onClose={() => setIsOpenMessage(false)}
                open={isOpenMessage}
                width={isMobile ? '100%' : '500px'}
                style={{ zIndex: 999999 }}
            >
                <MessageForm business={business} userinfo={userinfo} />
            </Drawer>

            {business.banner &&
                <div>
                    <img src={business.banner} alt={business.name} className='w-full h-[330px] bg-no-repeat bg-center smsize:bg-cover smsize:h-[180px]' />
                </div>
            }
            <div className='m-auto flex p-10 justify-between gap-10 smsize:flex-col smsize:p-5 smsize:relative shadow'>
                <div className=' smsize:absolute smsize:top-[-50px] bg-white w-[185px] h-[185px] smsize:w-[100px] smsize:h-[100px] rounded-full border-2 border-[#E4B456] flex items-center justify-center'>
                    {business.logo ?
                        <img src={business?.logo} alt={business.name} className=' rounded-full' />
                        :
                        <div className='text-[20px]'>{business.name}</div>
                    }
                </div>
                <div className='flex-1 flex flex-col text-[14px] smsize:text-[13px] font-light text-left gap-2 smsize:pt-[40px]'>
                    <h1 className='text-[48px] font-medium smsize:text-[16px]'>
                        {business?.name}
                    </h1>
                    <div className='flex gap-2 items-center'>
                        <FiPhoneCall /><strong> Phone:</strong> {business?.businessPhone}
                    </div>
                    <div className='flex gap-2 items-center'>
                        <GoHome /><strong> Address:</strong>  {business?.address}, {business?.city}, {business?.province} {business?.postalCode}
                    </div>
                    <div className='flex gap-2 items-center'>
                        <IoTimeOutline /><strong> Hours:</strong> {currentStatus} {openHour?.dayStart}: {openHour?.openAt} - {openHour?.closeAt}
                    </div>
                </div>
                <div className='flex flex-col gap-10'>
                    <div className='flex gap-3 justify-between smsize:flex-col'>
                        <div className='flex gap-3'>
                            {userinfo &&
                                <button className='min-w-[109px] flex items-center text-[13px] font-light justify-center gap-1 border border-black rounded-full px-3 py-1 hover:bg-[#EFEFEF]'>
                                    <CiHeart size={20} />Favourite
                                </button>
                            }
                            <button onClick={() => setIsOpenMessage(true)} className='min-w-[109px] flex items-center text-[13px] font-light justify-center gap-1 border border-black rounded-full px-3 py-1 hover:bg-[#EFEFEF]'>
                                <MdOutlineMessage size={20} />Message
                            </button>
                            <button className='min-w-[109px] flex items-center text-[13px] font-light justify-center gap-1 border border-black rounded-full px-3 py-1 hover:bg-[#EFEFEF]' onClick={() => {
                                // get the title of the page
                                // const title = document.title
                                // get the url of the page
                                const url = window.location.href
                                // copy the title and url to the clipboard
                                copy(`${business?.name} ${url}`)
                                console.log('Copied to clipboard:', url)
                                // show a message to the user
                                message.success('Store profile link copied')
                            }}>
                                <PiShareFatLight size={20} />Share
                            </button>
                        </div>
                        {/* <div className='flex gap-3 smsize:justify-end'>
                            <div><img src="/images/store/tiktok.png" alt="" /></div>
                            <div><img src="/images/store/instagram.png" alt="" /></div>
                            <div><img src="/images/store/facebook.png" alt="" /></div>
                        </div> */}
                    </div>
                    {(business.isVerified || business.reservationEmail) &&
                        <div className='bg-[#F4F4F4] rounded-full py-4 px-10 flex justify-between font-normal smsize:flex-col gap-3'>
                            {business.isVerified &&
                                <div className='flex gap-2 text-[16px]'>
                                    <BsPatchCheckFill size={24} />
                                    <div className='flex flex-col gap-[-5px] smsize:flex-row smsize:gap-5'>
                                        <h3 className='m-0 p-0'>Verified store</h3>
                                        {business.isLiveInventory &&
                                            <p className='text-[#A0A0A0]'>live inventory</p>
                                        }
                                    </div>
                                </div>
                            }
                            {business.reservationEmail &&
                                <div className='flex items-center gap-2'>
                                    <BsCheck size={24} /> reservation available
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className='w-full bg-[#F4F4F4] flex items-center justify-center pt-2 gap-14 text-[16px] font-semibold'>
                {tabs.map((t, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            setTab(t)
                            dispatch(setStoreProfileFilterChanged(true))
                        }}
                        className={`cursor-pointer leading-9 px-3 ${tab === t ? 'border-b-[3px] border-[#E4B456]' : ''}`}>
                        {t}
                    </div>
                ))}
            </div>
            {tab === 'shop' && <StoreMain isMobile={isMobile} business={business} location={location} />}
            {tab === 'about' && <StoreAbout isMobile={isMobile} business={business} location={location} />}
            {tab === 'media' && <StoreMedia isMobile={isMobile} business={business} location={location} />}
        </div>
    )
}
