/**
 *  Copyright Fitzba Technologies Inc. 2023
 *
 *  Take Zearch input and displays related products
 *
 * @author Josh Soke, Yang Ming
 * @version May 09, 2023
 */

import React, { useEffect, useState } from 'react'
import { BiSearchAlt2 } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux'
import { setSearchTitle, setSearchInput, setSearchHistory } from '../reducers/contentReducer'
import { useNavigate, useLocation } from 'react-router-dom'
import './NavZearchBar.css'
import { AutoComplete, Input } from 'antd'
import { getSuggestion } from '../services/api.service'
import { CloseOutlined } from '@ant-design/icons'
/**
 *
 * @returns Render a Search bar
 */
export default function NavZearchBar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  // const itemsFilter = useSelector(state => state.content.itemsFilter)
  const searchInput = useSelector(state => state.content.searchInput)
  const searchHistory = useSelector(state => state.content.searchHistory)
  const [openSearch, setOpenSearch] = useState(false)
  const [options, setOptions] = useState([])
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    if (searchInput || !isFocus)
      return
    if (searchHistory.length > 0) {
      setOptions(getSearchHistory(searchHistory))
      setOpenSearch(true)
    } else {
      setOptions([])
      setOpenSearch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, searchHistory])

  async function onChange(e) {
    setOptions([])
    try {
      const value = e.target.value
      dispatch(setSearchInput(value))
      if (value.length < 1)
        return
      const res = await getSuggestion(value)
      setOptions(res.data ? searchResult(res.data) : [])
      if (res.data.length > 0) {
        setOpenSearch(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = (value) => {
    // setOptions(value ? searchResult(value) : []);
    if (value) {
      dispatch(setSearchHistory({
        type: 'add',
        value: value
      }))
    }
    setOpenSearch(false)
    dispatch(setSearchTitle(value))
    console.log('location.pathname', location.pathname)
    if (location.pathname !== '/search')
      navigate('/search?keywords=' + value + '&page=1')
  }


  const onSelect = (value) => {
    if (value.startsWith('history:'))
      return
    dispatch(setSearchInput(value))
    handleSearch(value)
    setOpenSearch(false)
  }

  const onFocus = () => {
    setIsFocus(true)
    if (searchHistory.length > 0) {
      setOptions(getSearchHistory(searchHistory))
      setOpenSearch(true)
    }
  }

  const searchResult = (list) => {
    const options = list?.map((item) => {
      return {
        value: item?.name,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {item?.name}
            </span>
            {/* <span>{item?.count} results</span> */}
          </div>
        )
      }
    })
    return options
  }

  const getSearchHistory = (list) => {
    const options = list?.map((item) => {
      return {
        value: 'history:' + item,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <strong onClick={() => {
              onSelect(item)
            }}>
              {item}
            </strong>
            <span>
              <CloseOutlined onClick={() => {
                dispatch(setSearchHistory({
                  type: 'remove',
                  value: item
                }))
                if (searchHistory.length === 1) {
                  setOpenSearch(false)
                }
              }} />
            </span>
          </div>
        )
      }
    })
    return options
  }

  return (
    <div className='flex w-full max-w-[650px] flex-col rounded-full bg-fitzba-white-static'>
      <div className='zsearch-input'>
        <div className='flex flex-row items-center justify-around'>
          <BiSearchAlt2 size={20} />
        </div>
        <AutoComplete
          style={{
            width: '100%',
          }}
          options={options}
          open={openSearch}
          onSelect={onSelect}
          value={searchInput ? searchInput : ''}
        // overlayclassName='zseach-history-suggestion'
        >
          <Input
            className='zsearch-input-new'
            placeholder='What are you looking for today?'
            onChange={onChange}
            value={searchInput}
            defaultValue={searchInput}
            onFocus={onFocus}
            allowClear
            // bordered={false}
            variant="borderless"
            autoFocus={true}
            onBlur={() => {
              setIsFocus(false)
              setOpenSearch(false)
            }}
            onPressEnter={k => {
              handleSearch(searchInput)
            }}
            styles={{
              zIndex: 99999
            }}
          />
        </AutoComplete>
        <button
          className='h-9 w-9'
          onClick={() => {
            handleSearch(searchInput)
          }}
        />
      </div>
    </div>
  )
}
