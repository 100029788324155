/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileMobileBottom defines the bottom section of the store profile on mobile devices.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024
 */

import React from 'react'

/**
 * A button component for the bottom section of the store profile on mobile devices.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the button.
 * @param {boolean} props.isShow - A flag indicating whether the button should be shown.
 * @param {Function} props.handleClick - The function to call when the button is clicked.
 */

function StoreProfileBottomButton({
    title,
    isShow,
    handleClick
}) {
    return (
        <button
            className={`flex h-fit w-fit justify-center whitespace-nowrap rounded-full border border-black px-[16px] py-[7px] text-[13px] 
        ${isShow ? 'bg-[#EDEDED]' : ''} hover:bg-[#EDEDED]`}
            onClick={handleClick}
        >
            {title}
        </button>
    )
}

/**
 * The bottom section of the store profile on mobile devices.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.showInfo - A flag indicating whether the info button should be shown.
 * @param {boolean} props.showDepartments - A flag indicating whether the departments button should be shown.
 * @param {Function} props.handleClickBottomButton - The function to call when a bottom button is clicked.
 */

export default function StoreProfileMobileBottom({
    showInfo,
    showDepartments,
    handleClickBottomButton,
}) {
    return (
        <div className='mt-[10px] mb-[30px] flex w-full flex-row flex-nowrap justify-start gap-[20px]'>
            <StoreProfileBottomButton
                title='View Store Info'
                isShow={showInfo}
                handleClick={() => handleClickBottomButton('info')}
            />
            <StoreProfileBottomButton
                title='Brands / Departments'
                isShow={showDepartments}
                handleClick={() => handleClickBottomButton('departments')}
            />
        </div>
    )
}
