/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileLogo gets the logo of a store to display on the store profile component. 
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd'


export default function StoreProfileLogo({
    link,
    logo,
    name,
    handleClickStore,
}) {
    return (
        <Link onClick={handleClickStore} to={link}>
            {logo ? (
                <img
                    src={logo}
                    className={`rounded-full border object-contain`}
                    style={{
                        height: '100%',
                        width: '100%',
                        // borderWidth: '2px',
                        // borderColor: '#E4B456',
                    }}
                    alt='Store Logo'
                />
            ) : (
                <Avatar
                    className={`font-sbold mx-auto cursor-pointer break-words border bg-fitzba-white-static align-middle font-anke text-2xl text-fitzba-off-black-static`}
                    size={{ xs: 112, sm: 112, md: 160, lg: 160, xl: 160, xxl: 160, }}
                    // style={{
                    //     borderWidth: '2px',
                    //     borderColor: '#E4B456',
                    // }}
                >
                    {name}
                </Avatar>
            )}
        </Link>
    )
}
