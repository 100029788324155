/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './Products.css'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getStoreProducts } from '@services/api.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import ItemCard from '../../zearch/components/ItemCard'
import SectionTitle from './SectionTitle'
import ShowMore from '../../zearch/components/ShowMore'
import ItemCardMobile from '../../zearch/components/ItemCardMobile'
import { setStoreProfileFilter, setStoreProfileList } from 'reducers/contentReducer'

/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function DealProducts({
    business,
    isMobile
}) {
    const dispatch = useDispatch()
    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)
    const storeProfileFilter = useSelector(state => state.content.storeProfileFilter)
    const storeProfileList = useSelector(state => state.content.storeProfileList)
    const storeProfileFilterChanged = useSelector(state => state.content.storeProfileFilterChanged)

    const { addToCart } = ShoppingCartHook()
    const productListRef = React.useRef(null)

    const {
        isPending,
        // error,
        // data,
    } = useQuery({
        queryKey: ['getStoreProducts', 'list', location?.lat, location?.lng, business?._id, storeProfileFilter],
        queryFn: async () => {
            const params = {
                lat: location?.lat,
                lng: location?.lng,
                type: 'list',
                storeid: business?._id,
                ...storeProfileFilter
            }
            console.log('params', params)
            const res = await getStoreProducts(params)
            if (storeProfileFilterChanged) {
                dispatch(setStoreProfileFilter({ ...storeProfileFilter, currentPage: 1 }))
                dispatch(setStoreProfileList(res))
            } else {
                dispatch(setStoreProfileList({
                    ...res,
                    data: [...storeProfileList.data, ...res.data]
                }))
            }
            return res
        },
    })


    /**
        * onClickScrollBtn horizontally scrolls the list of products by a specified step.
        *
        * @param {number} step - The step size for the scroll.
    */
    const onClickShowmore = () => {
        console.log('onClickShowmore')
        dispatch(setStoreProfileFilter({ ...storeProfileFilter, currentPage: storeProfileFilter.currentPage + 1 }))
    }

    if (isPending)
        return <LoadingSection text='Loading items' height='200px' />

    // const products = data?.data || []

    return (
        <section className="w-full max-w-[1280px] relative m-auto flex flex-col">
            <SectionTitle title={'ALL ITEMS'} />
            <div className="w-full flex gap-5 text-left flex-wrap smsize:gap-3" ref={productListRef}>
                {storeProfileList?.data?.map((item) => {
                    if (isMobile)
                        return <ItemCardMobile
                            key={item._id}
                            item={item}
                            userinfo={userinfo}
                            handleAddToCart={addToCart}
                        />
                    return <ItemCard key={item?._id} userinfo={userinfo} item={item} handleAddToCart={addToCart} />
                })}
            </div>
            {storeProfileList.currentPage < storeProfileList.totalPages &&
                <div className='mt-5'>
                    <ShowMore onClick={onClickShowmore} />
                </div>
            }
        </section>
    )
}
