/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideCategory defines a component that allows users to filter by delivery method
 * 
 * @author Yang Ming
 * @version January 15th, 2024 
 */

import React, { useState } from 'react'
import { deliveryMethod } from '@data/filter.data'
import FilterSideSectionHeader from '../../zearch/components/FilterSideSectionHeader'

export default function FilterSideBrand({
  itemsFilter,
  setFilterDeliveryTypes,
  dispatch,
  isMobile
}) {


  const [isOpen, setIsOpen] = useState(!isMobile ? (itemsFilter?.deliveryTypes.length > 0) : !isMobile)

  // Get the current state of the payment filter
  // const isOpen = useSelector(state => state.user.filterState.payment)
  // // Function to set the state of the payment filter
  // const setIsOpen = (value) => {
  //   dispatch(setFilterState({ type: 'payment', value }))
  // }

  return (
    <div className='filter-item' style={{
      color: '#000'
    }}>
      <FilterSideSectionHeader
        title='Pick-Up/Reserve'
        isOpen={isOpen}
        onClickTitle={() => setIsOpen(!isOpen)}
        isHighlight={itemsFilter?.deliveryTypes.length > 0}
        color='#000'
      />
      <div className='filter-item-list' style={{ display: isOpen ? 'flex' : 'none', color: '#000' }}>
        {deliveryMethod?.map((item, index) => (
          <div className='filter-checkbox-item' key={index}>
            <label>
              <input
                value={item.value}
                type='checkbox'
                className={item.available ? 'custom-checkbox' : 'custom-disabled-checkbox cursor-not-allowed'}
                checked={itemsFilter?.deliveryTypes.includes(item.value)}
                disabled={!item.available}
                onChange={e => {
                  setFilterDeliveryTypes({
                    name: item.value,
                    checked: e.target.checked,
                  })
                }}
              />
              <span className='cursor-not-allowed truncate'>{item.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
