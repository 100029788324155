/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileDesc gets the description of a store to display on the store profile component.
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import ItemDesc from '../../itemDetail/components/ItemDesc'

export default function StoreProfileDesc({
  // ref,
  description
}) {

  // const [isOpen, setIsOpen] = React.useState(false)

  if (!description) return null
  return (
    <div className={`flex-1 text-[14px] `}>
      <ItemDesc description={description} className='text-black' />
    </div>
  )
}
