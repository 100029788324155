
import '../zearch/components/Filter.css'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setStoreProfileFilter
} from '@reducers/contentReducer'
import FilterSideBrand from './components/FilterSideBrand'
import FilterSideDelivery from './components/FilterSideDelivery'
import FilterSideCategoryList from '../zearch/components/FilterSideCategoryList'
import FilterSideCondition from './components/FilterSideCondition'
import FilterSidePrice from './components/FilterSidePrice'

export default function StoreFilter({
  business,
  isMobile
}) {
  const dispatch = useDispatch()
  const filterList = useSelector(state => state.content.filterList)
  const storeProfileFilter = useSelector(state => state.content.storeProfileFilter)

  const setFilterPrice = (value) => {
    dispatch(setStoreProfileFilter({
      ...storeProfileFilter,
      ...value
    }))
  }

  const setFilterCondition = (value) => {
    dispatch(setStoreProfileFilter({
      ...storeProfileFilter,
      condition: value.checked
        ? [...storeProfileFilter.condition, value.name]
        : storeProfileFilter.condition.filter(item => item !== value.name)
    }))
  }

  const setFilterDeliveryTypes = (value) => {
    dispatch(setStoreProfileFilter({
      ...storeProfileFilter,
      deliveryTypes: value.checked
        ? [...storeProfileFilter.deliveryTypes, value.name]
        : storeProfileFilter.deliveryTypes.filter(item => item !== value.name)
    }))
  }

  const setFilterBrand = (value) => {
    dispatch(setStoreProfileFilter({
      ...storeProfileFilter,
      brands: value.checked
        ? [...storeProfileFilter.brands, value._id]
        : storeProfileFilter.brands.filter(item => item !== value._id)
    }))
  }

  return (
    <div className='filter-wrapper' id='filter-wrapper' style={{
      color: '#000'
    }}>
      <div className='mx-auto w-[300px] min-h-screen border-r border-[#eee] px-10 font-lexend text-[15px] filter-content text-left' id="zearch-filter">
        <div className='filter-item mt-10'>
          <h3 className='text-[18px] font-[500] text-black'>FILTERS</h3>
        </div>
        {/* <FilterSideCategoryList
          dispatch={dispatch}
          itemsFilter={itemsFilter}
          isMobile={isMobile}
        /> */}
        <FilterSideBrand
          itemsFilter={storeProfileFilter}
          dispatch={dispatch}
          setFilterBrand={setFilterBrand}
          filterList={filterList}
          isMobile={isMobile}
          business={business}
        />
        <FilterSideDelivery
          setFilterDeliveryTypes={setFilterDeliveryTypes}
          itemsFilter={storeProfileFilter}
          dispatch={dispatch}
          isMobile={isMobile}
        />
        <FilterSidePrice
          setFilterPrice={setFilterPrice}
          itemsFilter={storeProfileFilter}
          dispatch={dispatch}
          isMobile={isMobile}
        />
        <FilterSideCondition
          setFilterCondition={setFilterCondition}
          itemsFilter={storeProfileFilter}
          dispatch={dispatch}
          isMobile={isMobile}
        />
      </div>
    </div>
  )
}
