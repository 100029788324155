/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * A list of Stores
 *
 * @author James Nguyen
 * @version May 23, 2023
 */
import React from 'react'
import { useSelector } from 'react-redux'
import StoreCard from './StoreCard'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { HelmetProvider } from 'react-helmet-async'
import StoreProfile from './StoreProfile'
import FilterSideSelectedMobile from './FilterSideSelectedMobile'
import MobileTopFilter from './MobileTopFilter'

export default function StoreList({
  // location,
  isShowFilterDetail,
  isMobile,
  dispatch,
  filterList
}) {
  // const dispatch = useDispatch()
  const storesList = useSelector(state => state.content.storesList)
  const storesListExisted = useSelector(
    state => state.content.storesListExisted,
  )
  const itemsFilter = useSelector(state => state.content.itemsFilter)
  const storesListRef = React.useRef(null)

  const onClickScrollBtn = step => {
    if (!storesListRef.current) return
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      storesListRef.current.scrollLeft += step > 0 ? 100 : -100
      scrollAmount += 50
      if (scrollAmount >= Math.abs(step)) {
        clearInterval(slideTimer)
      }
    }, 50)
  }

  let storesShowList = [...storesList?.data]
  // sort the selected stores to the top
  if (storesList?.data?.length > 0 && itemsFilter?.stores?.length > 0) {
    // sort the selected stores to the top
    storesShowList.sort((a, b) => {
      if (itemsFilter?.stores?.includes(a._id)) {
        return -1
      }
      return 1
    })
  }

  const keywordsList = itemsFilter?.title?.split(' ') || []

  if (isShowFilterDetail && isMobile) return null

  // console.log('itemsFilter', itemsFilter)

  let isShowStoreProfile = false

  // console.log('itemsFilter.stores', itemsFilter?.stores?.length)
  // console.log('storesList', storesList?.data?.length)
  let showStore = null

  if (storesList?.data?.length > 0 &&
    ((itemsFilter?.stores?.length === 1 && itemsFilter.onlyOneStore) ||
      storesList?.data?.length === 1)
  ) {
    isShowStoreProfile = true
    if (storesList?.data?.length === 1) {
      showStore = storesList?.data[0]
    } else if (itemsFilter?.stores?.length === 1) {
      showStore = storesList?.data.find(store => store._id === itemsFilter.stores[0])
    }
  }

  // console.log('isShowStoreProfile', isShowStoreProfile)
  // console.log('showStore', showStore)

  return (
    <HelmetProvider>
      <div className='items-content' id='items-content'>
        <FilterSideSelectedMobile
          dispatch={dispatch}
          itemsFilter={itemsFilter}
          filterList={filterList}
          isMobile={isMobile}
        />
        {storesList?.data?.length > 0 && (
          <div className='my-4 text-lg font-normal pl-5'>
            <h2>STORES</h2>
          </div>
        )}
        <div className='hide-scroll overflow-x-auto'>
          <div
            className={` w-full ${itemsFilter.onlyOneStore
              ? 'overflow-x-hidden'
              : 'overflow-x-scroll'
              }`}
            style={{
              scrollbarWidth: 'none',
            }}
          >
            <div className='relative mx-5 flex flex-row gap-2 md:ml-12'>
              {
                !isShowStoreProfile && storesList?.data?.length > 1 && (
                  <>
                    <span
                      className='scroll-left'
                      style={{ top: '60px', zIndex: 10, left: '-30px' }}
                      onClick={() => onClickScrollBtn(-500)}
                    >
                      <AiOutlineLeft />
                    </span>
                    <span
                      className='scroll-right'
                      style={{ top: '60px', zIndex: 10 }}
                      onClick={() => onClickScrollBtn(500)}
                    >
                      <AiOutlineRight />
                    </span>
                  </>
                )}
              {/* display only one store */}
              {isShowStoreProfile && showStore ? (
                <div key={showStore?._id} className='w-full'>
                  <StoreProfile
                    item={showStore}
                    stores={itemsFilter?.stores}
                  />
                </div>
              ) : (
                <div className='search-stores-list scroll-wrap' ref={storesListRef}>
                  <span className='scroll-left' style={{ top: '80px' }} onClick={() => onClickScrollBtn(-500)}><AiOutlineLeft /></span>
                  <span className='scroll-right' style={{ top: '80px' }} onClick={() => onClickScrollBtn(500)}><AiOutlineRight /></span>
                  {storesShowList?.map((item, index) => {
                    if (
                      itemsFilter?.stores.length === 0 ||
                      itemsFilter?.stores.includes(item._id)
                    )
                      return (
                        <StoreCard
                          key={index}
                          item={item}
                          stores={itemsFilter?.stores}
                          // setShowAllStores={setShowAllStores}
                          keywordsList={keywordsList}
                        />
                      )
                    return null
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}
