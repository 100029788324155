/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileBrand defines the brand section of the store profile. It is a sub-component of ProfileView and handles retreiving, displaying and interactions with the brands of a store.
 * Displays Brands as buttons within a container 
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { setFilterBrand } from '@reducers/contentReducer'

/**
 * A component for the brand section of a store profile.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.dispatch - The dispatch function from the Redux store.
 * @param {Function} props.setFilterBrandProfile - The function to set the brand filter.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {Array} props.brands - The list of brands.
 */

export default function StoreProfileBrand({
  dispatch,
  setFilterBrandProfile,
  itemsFilter,
  brands,
}) {

  const isMobile = useSelector(state => state.content.isMobile)
  const [isOpen, setIsOpen] = React.useState(!isMobile)

  if (!brands || brands.length === 0) return null

  // sort by first letter ignore cases
  brands.sort((a, b) => {
    if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
      return -1
    }
    if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
      return 1
    }
    return 0
  })

  function onClick() {
    setIsOpen(!isOpen)
  }

  function onClickClear() {
    dispatch(setFilterBrand(null))
  }

  return (
    <div className=' flex max-h-[180px] flex-1 flex-col gap-[10px] rounded-xl border border-[#D9D9D9] @container'>
      <>
        <div
          className={`flex h-fit w-full items-center justify-between cursor-pointer ${!isOpen ? 'rounded-xl' : 'rounded-t-xl'
            } bg-[#F3F3F3] px-[10px] py-[5px] text-[20px] font-medium sm:px-[20px]`}
        >
          <h3 onClick={onClick}>Brands</h3>
          <div className='flex items-center justify-center gap-3'>
            {itemsFilter.brands.length > 0 && <button className='filter-selected-clear-all' onClick={onClickClear}>Clear All</button>}
            <span onClick={onClick}>
              {isOpen ? <AiOutlineUp /> : <AiOutlineDown />}
            </span>
          </div>
        </div>
        {isOpen && (
          <div className='show-scroll grid gap-x-[17px] gap-y-[19px] overflow-y-scroll rounded-b-xl px-[10px] pb-[20px] @xs:grid-cols-2 @sm:px-[20px] @lg:grid-cols-2'>
            {brands.map(brand => (
              <div
                key={brand._id}
                className={`flex h-[31px] w-full items-center truncate rounded-md border cursor-pointer 
                ${itemsFilter.brands.find(id => id === brand._id) ? 'border-[#E4B456]' : 'border-[#D9D9D9]'} 
                px-2 text-[13px] hover:bg-[#D9D9D9]`}
                onClick={() => {
                  dispatch(setFilterBrandProfile(brand))
                }}
              >
                <span className='w-full truncate'>{brand.label}</span>
              </div>
            ))}
          </div>
        )}
      </>
    </div>
  )
}
