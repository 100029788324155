import React from 'react'
import BenefitItemLine from './BenefitItemLine'
import { Link } from 'react-router-dom'

export default function BenefitItem({
    title,
    benefits
}) {
    return (
        <div className='flex flex-col gap-3 smsize:w-full'>
            <div className='flex flex-col gap-3 rounded-[20px] border-2 border-[#D9D9D9] text-[18px] font-light p-7 whitespace-nowrap smsize:whitespace-normal'>
                <h3 className='uppercase text-[32px] '>{title}</h3>
                <ul className='flex flex-col gap-3'>
                    {benefits.map((text, index) => (
                        <BenefitItemLine key={index} text={text} />
                    ))}
                </ul>
            </div>
            <Link to={title === 'As a consumer' ? '/about' : '/retail'} className='text-right text-[16px] font-light'>
                learn more {title === 'As a consumer' ? 'about' : 'join'} fitzba {'>'}
            </Link>
        </div>
    )
}
