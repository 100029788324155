/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartSummary is a functional React component that displays a summary of the user's shopping cart.
 * It shows the subtotal, taxes, and total for the items in the cart.
 * It also provides a form for the user to enter their email to receive their shopping list.
 * If the user is not logged in, it provides a button to log in to save their list.
 * The user can also send their shopping list to their email.
 *
 * @author James Nguyen, Yang Ming
 * @version June 29th, 2023 
 */

import React from 'react'
import { formatCurrency } from '@utils/Helper'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import { sendShoppingListEmail } from '@services/api.shoppingCart.service'

/**
 * Displays a summary of the user's shopping cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.shoppingCart - The user's shopping cart.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartSummary({ shoppingCart }) {
  const userinfo = useSelector(state => state.user.userinfo)
  const location = useSelector(state => state.user.location)

  const [sendEmail, setSendEmail] = React.useState(userinfo?.username || '')
  const [isSending, setIsSending] = React.useState(false)
  const [buttonName, setButtonName] = React.useState('Send shopping list')
  const cartData = shoppingCart?.data
  const handleSendShoppingList = async () => {
    if (!sendEmail) {
      message.error('Please enter your email')
      return
    }
    const hide = message.loading('Sending email...')
    try {
      setIsSending(true)
      await sendShoppingListEmail(sendEmail, location)
      let time = 3
      const tick = setInterval(() => {
        time--
        setButtonName(`Email Sent`)
        if (time === 0) {
          clearInterval(tick)
          setIsSending(false)
          setButtonName('Send shopping list')
          return
        }
        console.log('time', time)
      }, 1000)
      hide()
      message.success('Email sent successfully')
    } catch (error) {
      hide()
      setIsSending(false)
      setButtonName('Send shopping list')
      message.error('Error sending email: ' + error?.message || '')
    }
  }
  return (
    <div className='cart-summary' id='cart-summary'>
      <div className='cart-summary-header'>
        <h3>Summary</h3>
        <p>({cartData?.selectedItems || 0}) ITEMS</p>
        <div className='cart-summary-total'>
          <div className='cart-total-line'>
            <span>Subtotal</span>
            <span>{formatCurrency(cartData?.subTotal)}</span>
          </div>
          <div className='cart-total-line'>
            <span>Taxes</span>
            <span>{formatCurrency(cartData?.taxTotal)}</span>
          </div>
          <p className='cart-total-border-bottom' />
          <div className='cart-total-line' style={{ paddingTop: 10 }}>
            <span>TOTAL</span>
            <span>{formatCurrency(cartData?.total)}</span>
          </div>
          <p className='cart-total-border-bottom' />
        </div>
      </div>
      <div className='cart-summary-other'>
        <h4>Receive your shopping list directly to your email.</h4>
        <div>
          <div className='cart-summary-input'>
            <label>Email:</label>
            <input
              type='email'
              placeholder='Enter your email'
              value={sendEmail}
              onChange={e => {
                console.log('e.target.value', e.target.value)
                setSendEmail(e.target.value)
              }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          <button onClick={handleSendShoppingList} disabled={isSending}>
            {buttonName}
          </button>
        </div>
      </div>
      {userinfo == null && (
        <div className='cart-summary-other'>
          <h4>Login/Create an account to SAVE your list.</h4>
          <div style={{ textAlign: 'right' }}>
            <Link to='/login'>
              <button style={{ backgroundColor: '#000' }}>login to save</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
