/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * api.business.service.js includes API calls for business services such as:
 * Employee verification (getEmployeeVerify, checkEmployeeVerify)
 * Profile retrieval by shortlink (getProfileByShortlink)
 * Distance retrieval to a user's store (getUserStoreDistance)
 * 
 * @author Yang Ming
 * @version June 06, 2023
 */

import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
  baseURL: Config.apiUrl + Config.apiPrefix,
  timeout: 60 * 1e3,
  // 5000
})

httpClient.interceptors.request.use(config => {
  // Perform logic before sending request
  config.headers['authorization'] =
    `${Config.apiKey} ` + localStorage.getItem('token')
  return config
})

/**
 * Verifies an employee.
 *
 * @param {string} employee_id - The ID of the employee to verify.
 * @param {string} business_id - The ID of the business.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function getEmployeeVerify(employee_id, business_id) {
  try {
    const response = await httpClient.get(`/business/employee/verify/${employee_id}`, {
      params: {
        business_id: business_id
      }
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}


/**
 * Checks an employee's verification status.
 *
 * @param {string} employee_id - The ID of the employee to check.
 * @param {Object} params - The parameters for the request.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function checkEmployeeVerify(employee_id, params) {
  try {
    const response = await httpClient.put(`/business/employee/verify/${employee_id}`, params)
    return response.data
  } catch (error) {
    throw error.response
  }
}

/**
 * Retrieves a profile by its shortlink.
 *
 * @param {string} title - The title of the profile.
 * @param {string} city - The city of the profile.
 * @returns {Promise} A promise that resolves to the response data, or null if no title is provided.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function getProfileByShortlink(title, city) {
  try {
    if (!title) return null
    const response = await httpClient.get(`/business/shortlink/${title}`, {
      params: {
        city: city
      }
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

/**
 * Retrieves the distance to a user's store.
 *
 * @param {Object} location - The location of the user's store.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function getUserStoreDistance(location) {
  try {
    const response = await httpClient.get(`/business/getDistance`, {
      params: location
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

export async function getUserBusinessList(userId) {
  try {
    const response = await httpClient.get(`/business/selectList`, {
      userId: userId
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

export async function getStoresList(params) {
  try {
    const response = await httpClient.get(`/business/stores`, {
      params: params
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

export async function addBusinessMessage(params) {
  try {
    const res = await httpClient.post(`/businessMessage`, params)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getStoreBrandList(params) {
  try {
    const response = await httpClient.get(`/brand/storeBrandList`, {
      params: params
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}