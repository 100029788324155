import React, { useContext } from 'react'
import { Button, Form, Input, Tabs, message } from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import { Config } from '@Config'
import { AuthContext } from '@contexts/AuthProvider'
import { registerConsumerV3 } from '@services/api.service'
import { useDispatch } from "react-redux"
import { setUserinfo } from '@reducers/userReducer'

const useAuth = () => useContext(AuthContext)

function CreateNewAccount({
    setCurrent,
    recaptchaRef,
    recaptchaCode,
    setRecaptchaCode,
}) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const onFinish = async (values) => {
        console.log('onFinish:', values)
        try {
            const res = await registerConsumerV3(values)
            console.log('onFinish', res)
            message.success('Register succsessfully')
            dispatch(setUserinfo(res.data))
            setCurrent(1)
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Register failed, please try again')
        }
    }
    return <Form
        form={form}
        name="createNewAccount"
        style={{
            maxWidth: 400,
            textAlign: 'left',
        }}
        onFinish={onFinish}
        layout='vertical'
        autoComplete="off"
    >
        <Form.Item name="username"
            label="Email"
            width="md"
            rules={[
                {
                    required: true,
                    message: 'Email is required',
                },
                {
                    type: 'email',
                    message: 'Email is not valid',
                },
            ]}>
            <Input

            />
        </Form.Item>
        <Form.Item style={{
            marginBottom: 0,
        }}
        >
            <Form.Item name="password"
                label="Password"
                width="sm"
                rules={[
                    {
                        required: true,
                        message: 'Password is required',
                    },
                ]}
                style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                    marginRight: '16px',
                }}
            >
                <Input.Password

                />
            </Form.Item>
            <Form.Item name="confirmPassword"
                label="Confirm Password"
                width="sm"
                rules={[
                    {
                        required: true,
                        message: 'Confirm Password is required',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                            }
                            return Promise.reject(new Error('Passwords do not match!'))
                        },
                    }),
                ]}
                style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                }}
            >
                <Input.Password

                />
            </Form.Item>
        </Form.Item>
        <Form.Item style={{
            marginBottom: 0,
        }}>
            <Form.Item name="firstName"
                label="First Name"
                width="sm"
                rules={[
                    {
                        required: true,
                        message: 'First name is required',
                    },
                ]}
                style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                    marginRight: '16px',
                }}
            >
                <Input

                /></Form.Item>
            <Form.Item name="lastName"
                label="Last Name"
                width="sm"
                rules={[
                    {
                        required: true,
                        message: 'Last name is required',
                    },
                ]}
                style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                }}
            ><Input

                /></Form.Item>
        </Form.Item>
        <Form.Item name="phone" label="Phone Number" width="sm" rules={[
            {
                required: true,
                message: 'Phone Number is required',
            },
        ]}>
            <Input />
        </Form.Item>
        <Form.Item label=" " colon={false}>
            <Button
                htmlType='submit'
                className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-black py-2 px-5 text-white hover:bg-slate-200'
            >
                Create
            </Button>
        </Form.Item>
    </Form>
}

function ExsitingAccount({
    setCurrent,
    recaptchaRef,
    recaptchaCode,
    setRecaptchaCode,
}) {
    const auth = useAuth()
    const [form] = Form.useForm()
    const onFinish = async (values) => {
        console.log('onFinish:', values)
        if (!recaptchaCode) {
            message.error('Please verify that you are not a robot')
            return
        }

        await auth.signIn(
            {
                ...values,
                recaptchaCode,
            },
            () => {
                console.log('login success')
                setCurrent(1)
            },
        )
    }

    return <Form
        form={form}
        name="exsitingAccount"
        style={{
            maxWidth: 400,
            textAlign: 'left',
        }}
        onFinish={onFinish}
        layout='vertical'
        autoComplete="off"
    >
        <Form.Item name="username"
            label="Email"
            width="md"
            rules={[
                {
                    required: true,
                    message: 'Email is required',
                },
                {
                    type: 'email',
                    message: 'Email is not valid',
                },
            ]}>
            <Input

            />
        </Form.Item>
        <Form.Item name="password"
            label="Password"
            width="sm"
            rules={[
                {
                    required: true,
                    message: 'Password is required',
                },
            ]}>
            <Input.Password

            />
        </Form.Item>
        <ReCAPTCHA
            sitekey={Config.gogoleRecaptchaSiteKey}
            onChange={setRecaptchaCode}
            style={{
                marginBottom: '0px',
            }}
            ref={recaptchaRef}
        />
        <Form.Item label=" " colon={false}>
            <Button
                htmlType='submit'
                className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-black py-2 px-5 text-white hover:bg-slate-200'
            >
                Login
            </Button>
        </Form.Item>
    </Form>
}

export default function RegisterStep1({
    setCurrent,
    recaptchaRef,
    recaptchaCode,
    setRecaptchaCode,
}) {


    return (
        <div className='py-8 m-auto w-[400px]'>
            <Tabs
                defaultActiveKey="1"
                centered
                items={[
                    {
                        key: '1',
                        label: 'Create New Account',
                        children: <CreateNewAccount
                            setCurrent={setCurrent}
                            recaptchaRef={recaptchaRef}
                            recaptchaCode={recaptchaCode}
                            setRecaptchaCode={setRecaptchaCode}
                        />,
                    },
                    {
                        key: '2',
                        label: 'Use Existing Account',
                        children: <ExsitingAccount
                            setCurrent={setCurrent}
                            recaptchaRef={recaptchaRef}
                            recaptchaCode={recaptchaCode}
                            setRecaptchaCode={setRecaptchaCode}
                        />,
                    }
                ]}
            />
        </div>
    )
}
