/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * A list of shopping cart API to use throughout the app
 * 
 * @author Yang Ming
 * @version June 07, 2023
 */
import { Config } from '../Config'
import axios from 'axios'


const httpClient = axios.create({
    baseURL: Config.apiUrl + Config.apiPrefix,
    timeout: 60 * 1e3,
})

httpClient.interceptors.request.use(config => {
    // Perform logic before sending request
    config.headers['authorization'] = `${Config.apiKey} ` + localStorage.getItem('token')
    return config
})

export async function getShoppingCartList() {
    try {
        const tmp_id = localStorage.getItem('tmp_id')
        const token = localStorage.getItem('token')
        const api_route = tmp_id || !token ? `/shoppingTmpCart` : `/shoppingCart`
        const res = await httpClient.get(api_route, { params: { tmp_id } })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function sendShoppingListEmail(email, location) {
    try {
        const tmp_id = localStorage.getItem('tmp_id')
        const api_route = tmp_id ? `/shoppingCartTmpEmail` : `/shoppingCartEmail`
        const res = await httpClient.post(api_route, {
            tmp_id,
            email,
            lat: location.lat,
            lng: location.lng
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getShoppingCartIds() {
    try {
        const res = await httpClient.get(`/shoppingCartIds`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function addShoppingCart(params) {
    try {
        const tmp_id = localStorage.getItem('tmp_id')
        const api_route = tmp_id ? `/shoppingTmpCart` : `/shoppingCart`
        const res = await httpClient.post(api_route, {
            ...params,
            tmp_id: tmp_id
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function updateShoppingCart(id, params) {
    try {
        const tmp_id = localStorage.getItem('tmp_id')
        const api_route = tmp_id ? `/shoppingTmpCart` : `/shoppingCart`
        console.log('api_route', api_route)
        console.log('tmp_id', tmp_id)
        const res = await httpClient.put(`${api_route}/${id}`, {
            ...params,
            tmp_id: tmp_id
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function deleteShoppingCart(id) {
    try {
        const tmp_id = localStorage.getItem('tmp_id')
        const api_route = tmp_id ? `/shoppingTmpCart` : `/shoppingCart`
        const res = await httpClient.delete(`${api_route}/${id}`, {
            params: {
                tmp_id: tmp_id
            }
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function checkoutShoppingCart() {
    try {
        const res = await httpClient.post(`/shoppingCart/checkout`)
        return res.data
    } catch (error) {
        throw error.response
    }
}