/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The SiteMap component displays a sitemap of the website, including categories, shop by distance, and more about Fitzba.
 * It fetches the global category list from the server and generates links for each category and its children.
 * The component uses the useState and useEffect hooks from React, the useDispatch hook from react-redux, and the useNavigate hook from react-router-dom.
 * The getGlobalCategoryList function from the api.globalCategory.service is used to fetch the global category list.
 *
 * @author James Nguyen
 * @version October 19th, 2023 
 */

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getGlobalCategoryList } from '@services/api.globalCategory.service'
import {  setGotoElement } from '../reducers/contentReducer'
import { useNavigate } from 'react-router-dom'
// import { getBrandList } from '../services/api.service'
// import Link from 'antd/es/typography/Link'

const SiteMap = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [categories, setCategories] = useState([])
  // const [brands, setBrands] = useState([])
  let linkList = []

  const otherCategories = []

  /**
 * Traverses a category and its children to collect their IDs and generate a search link.
 * @param {Object} category - The category to traverse.
 */
  const collectCategoryLinks = category => {
    const idArray = []

    const traverse = node => {
      if (node._id) {
        idArray.push(node._id)
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverse(child)
        })
      }
    }

    traverse(category)

    const idString = idArray.join(',')
    linkList.push(`/search?categories=${idString}`)
  }

  //   useEffect(() => {
  //     const fetchBrands = async () => {
  //       try {
  //         const res = await getBrandList()
  //         setBrands(res.data)
  //         // console.log(res.data)
  //       } catch (error) {
  //         console.log(error)
  //       }
  //     }
  //     fetchBrands()
  // })

  useEffect(() => {
    const getGlobalCategory = async () => {
      try {
        const res = await getGlobalCategoryList({})
        console.log(res)
        setCategories(res.data)
        // Extract links from the categories and their children
        res.data.forEach(category => {
          collectCategoryLinks(category)
          if (category?.children?.length > 0) {
            category.children.forEach(child => {
              collectCategoryLinks(child)
              if (child?.children?.length > 0) {
                child.children.forEach(grandChild => {
                  collectCategoryLinks(grandChild)
                })
              }
            })
          }
          // console.log(linkList)
        })
      } catch (error) {
        console.log('getGlobalCategoryList', error)
      }
    }
    getGlobalCategory()
    // console.log(linkList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  /**
 * Handles click events on category elements.
 * Traverses the category and its children to collect their IDs and navigate to a search page.
 * @param {Object} data - The category data.
 */
  const onClickCategories = data => {
    const collectIds = obj => {
      const idArray = []

      const traverse = node => {
        if (node._id) {
          idArray.push(node._id)
        }
        if (node.children && node.children.length > 0) {
          node.children.forEach(child => {
            traverse(child)
          })
        }
      }

      traverse(obj)

      return idArray
    }

    const allIds = collectIds(data)
    const idString = allIds.join(',')
    navigate(`/search?categories=${idString}`)
  }

  return (
    <div className='md py-10 phone:px-10 px-2 md:px-5 '>
      <h1 className='text-[32px] font-medium w-full text-left'>Sitemap</h1>
      <div>
        <h2 className='w-full text-left text-[22px] py-[50px] font-medium'>Categories</h2>
        <div className='grid grid-cols-2 gap-y-[70px] gap-x-2 md:grid-cols-3 phone:grid-cols-4 lg:grid-cols-5 grid-flow-row-dense'>
          {categories?.map(parentCategory => {
            if (
              !parentCategory.children ||
              parentCategory.children.length === 0
            ) {
              otherCategories.push(parentCategory)
              return null // Do not display this category
            }
            return (
              <div
                key={parentCategory._id}
                className={`text-left text-[16px] ${
                  parentCategory.title === 'Clothing' && 'col-span-2 md:col-span-3'
                } ${
                  parentCategory.title === 'Pets' && 'col-span-2 md:col-span-3 phone:col-span-4'
                }`}
              >
                <span
                  className={`cursor-pointer underline font-medium `}
                  onClick={() => {
                    onClickCategories(parentCategory)
                  }}
                >
                  {parentCategory.title}
                </span>
                <div
                  className={`pt-[23px] ${
                    parentCategory.title === 'Clothing' &&
                    'grid grid-cols-2 md:grid-cols-3 grid-flow-row-dense'
                  } ${
                    parentCategory.title === 'Pets' &&
                    ' grid grid-flow-row grid-cols-2 md:grid-cols-3 phone:grid-cols-4'
                  } ${
                    (parentCategory.title === 'Clothing' ||
                      parentCategory.title === 'Pets') ?
                    'gap-y-[70px]' : 'flex flex-col gap-y-[10px]'
                  }`}
                >
                  {parentCategory?.children?.map(childCategory => (
                    <div
                      key={childCategory._id}
                      className={`text-left text-[14px] ${
                        (parentCategory.title === 'Clothing' ||
                          parentCategory.title === 'Pets') &&
                        'col-span-1 text-[16px]'
                      } `}
                    >
                      <span
                        className={`cursor-pointer hover:underline ${(parentCategory.title === 'Clothing' ||
                        parentCategory.title === 'Pets') && 'font-medium'}`}
                        onClick={() => {
                          onClickCategories(childCategory)
                        }}
                      >
                        {childCategory.title}
                      </span>
                      <div className={`mt-[5px] flex flex-col gap-y-[10px]`}>
                        {childCategory?.children?.map(grandChildCategory => (
                          <div
                            key={grandChildCategory._id}
                            className='text-left text-[14px]'
                          >
                            <span
                              className='cursor-pointer hover:underline'
                              onClick={() => {
                                onClickCategories(grandChildCategory)
                              }}
                            >
                              {grandChildCategory.title}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
          {otherCategories.length > 0 && (
            <div
              key='others'
              className={`col-span-1 text-left text-[16px]`}
            >
              <span
                className='cursor-pointer underline'
                onClick={() => {
                  // Handle the click for "Others"
                }}
              >
                Others
              </span>
              <div className={`pt-[23px] flex flex-col gap-y-[15px]`}>
                {otherCategories.map(otherCategory => (
                  <div
                    key={otherCategory._id}
                    className='text-left text-[14px]'
                  >
                    <span
                      className='cursor-pointer hover:underline'
                      onClick={() => {
                        onClickCategories(otherCategory)
                      }}
                    >
                      {otherCategory.title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div>
        <h2 className='w-full text-left text-[28px] font-medium'>Brands</h2>
        <div>

        </div>
      </div> */}
      <div>
        <h2 className='w-full text-left text-[22px] mt-[130px] font-medium'>
          Shop by distance
        </h2>
        <div className='mt-5 grid grid-cols-2 gap-y-[70px] gap-x-2 md:grid-cols-3 phone:grid-cols-4 lg:grid-cols-5'>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/search?ref=fitzba&distance=10&condition=New')
            }}
          >
            Shop up to 10 Km
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/search?ref=fitzba&distance=25&condition=New')
            }}
          >
            Shop up to 25 Km
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/search?ref=fitzba&distance=50&condition=New')
            }}
          >
            Shop up to 50 Km
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/search?ref=fitzba&distance=100&condition=New')
            }}
          >
            Shop up to 100 Km
          </span>
        </div>
      </div>
      <div>
        <h2 className='w-full text-left text-[22px] mt-[130px] font-medium'>
          More about fitzba
        </h2>
        <div className='mt-5 grid grid-cols-2 gap-y-[70px] gap-x-2 md:grid-cols-3 phone:grid-cols-4 lg:grid-cols-5'>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/about')
            }}
          >
            What is fitzba
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/faq')
            }}
          >
            Frequently asked questions
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/retail')
            }}
          >
            Fitzba for retailers
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/demo')
            }}
          >
            Book a demo
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              navigate('/contact')
            }}
          >
            Get in touch
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              dispatch(setGotoElement('terms'))
              navigate('/policy')
            }}
          >
            Term of Use
          </span>
          <span
            className='cursor-pointer text-left text-[16px] underline w-fit'
            onClick={() => {
              dispatch(setGotoElement('policy'))
              navigate('/policy')
            }}
          >
            Privacy Policy
          </span>
        </div>
      </div>
    </div>
  )
}

export default SiteMap
