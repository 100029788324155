/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileMobileTop defines the top section of the store profile on mobile devices.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 15th, 2024 
 */
import React from 'react'
import StoreProfileLeft from './StoreProfileLeft'
import StoreProfileDesc from './StoreProfileDesc'

export default function StoreProfileMobileTop({
    isMobile,
    business,
    handleClickStore
}) {
    return (
        <div className='flex flex-row gap-5'>
            <StoreProfileLeft
                isMobile={isMobile}
                business={business}
                handleClickStore={handleClickStore}
            />
            <StoreProfileDesc
                description={business?.description}
            />
        </div>
    )
}
