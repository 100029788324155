import React from 'react'
import AppleLogin from 'react-apple-login'
import { FaApple } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBusiness, setUserinfo } from '@reducers/userReducer'
import { setSearchHistory } from '@reducers/contentReducer'
import { message } from 'antd'
import { ssoLoginApple } from '@services/api.service'

export default function SSOLoginApple() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const backToRoute = useSelector(state => state.temp.backToRoute) || '/'
    const appleResponse = async (response) => {
        if (response.error) {
            console.error(response.error)
            message.error('Sign in with Apple failed, please try again.')
            return
        }
        try {
            // const access_token = response.access_token
            const res = await ssoLoginApple(response)
            console.log('appleResponse', res)
            dispatch(setUserinfo(res.data))
            dispatch(setBusiness(res.data?.isBusiness || false))
            if (res?.data?.searchHistory) {
                dispatch(setSearchHistory(res.data.searchHistory || []))
            }
            localStorage.removeItem('tmp_id')
            navigate(backToRoute, { replace: true })
        } catch (err) {
            console.log('appleResponse error:', err)
        }
    }

    return (
        <AppleLogin
            clientId="com.fitzba.www"
            redirectURI={`${process.env.REACT_APP_API_URL}/api/f/ssoLoginApple`}
            usePopup={true}
            callback={appleResponse} // Catch the response
            scope="email name"
            responseMode="query"
            render={(props) => <button {...props} className='h-[43px] border rounded-full bg-black text-white border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center'>
                <FaApple size={30} /> Sign in with Apple
            </button>}
        />
    )
}
