import React from 'react'
import { Modal } from 'antd'
// import Loading from '@components/Loading'
import { contentCode } from '@data/contentCode.data'
import ContentDataHook from '@hooks/ContentData.hook'

export default function StatementModal({ isMobile, isOpen, setIsOpen }) {
  const {
    isPending,
    // error,
    data: payStatement,
  } = ContentDataHook(contentCode.PAY_STATEMENT)
  //   if (isPending) return <Loading />
  //   if (error) return 'StatementModal: An error has occurred: ' + error.message

  return (
    <Modal
      title='The statement of Fitzba Payment'
      open={isOpen}
      onOk={() => {
        setIsOpen(false)
      }}
      footer={null}
      onCancel={() => setIsOpen(false)}
      width={800}
      centered={true}
      style={{
        top: isMobile ? 75 : 0,
      }}
    >
      {isPending ? (
        <div>Loading...</div>
      ) : (
        <p
          className='h-[600px] overflow-y-auto'
          dangerouslySetInnerHTML={{ __html: payStatement?.content }}
        ></p>
      )}
    </Modal>
  )
}
