import React, { useEffect, useRef } from 'react'
import { IoChevronDownSharp } from "react-icons/io5"
import { Spin } from 'antd'
import { useSelector } from 'react-redux'
const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
}
const content = <div style={contentStyle} />


export default function SearchSeemore({
    onClickSeeMore,
    isMobile
}) {
    const isLoadingSeeMore = useSelector(state => state.content.isLoadingSeeMore)
    const seeMoreRef = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        refreshData()
                    }
                })
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
            }
        )

        if (seeMoreRef.current) {
            observer.observe(seeMoreRef.current)
        }

        return () => {
            if (seeMoreRef.current) {
                observer.unobserve(seeMoreRef.current)
            }
        }
    }, [])

    const refreshData = () => {
        console.log('Refreshing data...')
        if (isMobile)
            onClickSeeMore()
    }

    return (
        <div className='w-full flex justify-center items-center py-8'>
            {isLoadingSeeMore ?
                <Spin tip="Loading" size="large">
                    {content}
                </Spin>
                :
                <button ref={seeMoreRef} onClick={onClickSeeMore} className='flex rounded-full bg-black text-white justify-center items-center py-4 px-12 gap-2'>See more <IoChevronDownSharp /></button>
            }
        </div>
    )
}
