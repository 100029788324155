/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * A list of item cards
 *
 * @author Yang Ming
 * @version May 09, 2023
 */
import React, { useState } from 'react'
// import { useSearchParams } from 'react-router-dom'

import SearchPagination from './SearchPagination'
import ItemCard from './ItemCard'
import ItemCardMobile from './ItemCardMobile'
import { useSelector, useDispatch } from 'react-redux'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import ResultHeader from './ResultHeader'
import ItemEmpty from './ItemEmpty'
import SearchSeemore from './SearchSeemore'
import {
  // initItemsFilter,
  // setItemsList,
  // initItemsList,
  // setFilterSideBrands,
  // setCategoryList,
  // setFilterSideStores,
  setFilterSortBy,
  // setCategoryListExisted,
  // setStoresListExisted,
  SORTBY_CODE,
  // setFilterStoreAndKeywords,
  // setStoresList,
  // setIsReloadItemsList,
  setFilterCondition
} from '@reducers/contentReducer'
// import { getInventoryList } from '@services/api.service'
// import { Spin } from 'antd'
// import LoadingSpin from '@components/LoadingSpin'
import LoadingSection from '@components/LoadingSection'
import './ItemListNew.css'


export default function ItemList({
  isShowFilterDetail,
  isMobile,
  itemsList,
  isLoading,
  searchParams,
  onClickSeeMore
}) {
  const dispatch = useDispatch()
  const { addToCart } = ShoppingCartHook()
  const itemsFilter = useSelector(state => state.content.itemsFilter)
  const userinfo = useSelector(state => state.user.userinfo)
  const [isGrid, setIsGrid] = useState(false)
  // Handle sorting filter change
  const onClickSortBy = e => {
    dispatch(setFilterSortBy(Number(e.target.value)))
  }

  const keywordsList = itemsFilter?.title?.split(' ') || []

  if (isShowFilterDetail && isMobile) return null

  if (isLoading)
    return <LoadingSection text='Loading Items' />

  return (

    <div className='items-content'>
      <ResultHeader
        onClickSortBy={onClickSortBy}
        itemsFilter={itemsFilter}
        itemsList={itemsList}
        SORTBY_CODE={SORTBY_CODE}
        userinfo={userinfo}
        isGrid={isGrid}
        setIsGrid={setIsGrid}
        isMobile={isMobile}
      />
      {(!itemsList || itemsList?.total === 0) &&
        <ItemEmpty keywords={itemsFilter.title} />
      }
      {itemsList?.total > 0 && (
        <>
          {(isMobile && isGrid === false) ? (
            <div className='mobile-items'
            >
              <div className='mobile-items-list'>
                {itemsList?.data?.map(item => (
                  <ItemCardMobile
                    key={item._id}
                    item={item}
                    userinfo={userinfo}
                    handleAddToCart={addToCart}
                    keywordsList={keywordsList}
                    searchParams={searchParams}
                    setFilterCondition={setFilterCondition}
                    dispatch={dispatch}
                  />
                ))}
              </div>
            </div>
          ) :
            <div className='items-content-list'>
              <div className='grid w-full grid-cols-2 gap-x-4 gap-y-5 sm:gap-x-8 md:grid-cols-4 3xl:grid-cols-5 3xl:gap-x-2'>
                {itemsList?.data?.map(item => (
                  <ItemCard
                    key={item._id}
                    item={item}
                    userinfo={userinfo}
                    handleAddToCart={addToCart}
                    keywordsList={keywordsList}
                    searchParams={searchParams}
                  />
                ))}
              </div>
            </div>
          }
          <SearchSeemore onClickSeeMore={onClickSeeMore} isMobile={isMobile}/>
          {/* <SearchPagination itemsList={itemsList} /> */}
        </>
      )}
    </div>
  )
}
