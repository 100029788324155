/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * A list of recommended APIs to use throughout the app
 * 
 * @author Yang Ming
 * @version June 06, 2023
 */
import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
    baseURL: Config.apiUrl + '/api',
    timeout: 60 * 1e3,
})

httpClient.interceptors.request.use(config => {
    // Perform logic before sending request
    config.headers['authorization'] = `${Config.apiKey} ` + localStorage.getItem('token')
    return config
})

export async function getGlobalCategoryList(params) {
    try {
        const res = await httpClient.get(`/globalCategory/filter`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getGlobalCategorySelected(params) {
    try {
        const res = await httpClient.get(`/globalCategory/selected`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getGlobalCategoryRemoveSelected(params) {
    try {
        const res = await httpClient.get(`/globalCategory/removeSelected`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getGlobalCategoryTopHeaderList() {
    try {
        const res = await httpClient.get(`/globalCategory/topHeaderList`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getGlobalCategoryTopHeaderListNew() {
    try {
        const res = await httpClient.get(`/globalCategory/topHeaderListNew`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getGlobalCategoryPopularList() {
    try {
        const res = await httpClient.get(`/globalCategory/popularList`)
        return res.data
    } catch (error) {
        throw error.response
    }
}