import React, { useEffect } from 'react'
import { IoIosSearch } from 'react-icons/io'
import StoreFilter from './StoreFilter'
import DealProducts from './components/DealProducts'
import NewItemsProducts from './components/NewItemsProducts'
import AllProducts from './components/AllProducts'
import { setStoreProfileFilter } from 'reducers/contentReducer'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

export default function StoreMain({
    isMobile,
    business,
    location
}) {
    const dispatch = useDispatch()
    const storeProfileFilter = useSelector(state => state.content.storeProfileFilter)
    const storeProfileList = useSelector(state => state.content.storeProfileList)
    const [input, setInput] = React.useState(storeProfileFilter.title || '')

    useEffect(() => {
        setTimeout(() => {
            setInput(storeProfileFilter.title)
        }, 1000)
    }, [storeProfileFilter.title])

    const handleChange = debounce((value) => {
        console.log('onSearch', value)
        dispatch(setStoreProfileFilter({
            ...storeProfileFilter,
            title: value
        }))
    }, 600)

    return (
        <div className='w-full flex h-fit bg-white'>
            <StoreFilter business={business} isMobile={isMobile} />
            <div className='flex-1 w-full py-[35px] px-[65px] flex flex-col gap-3 smsize:p-5'>
                <div className='w-full flex justify-between items-center'>
                    <div className='flex gap-3 smsize:hidden'>
                        <button className='border border-black px-4 rounded-full py-1'>brands</button>
                        <button className='border border-black px-4 rounded-full py-1'>categories</button>
                    </div>
                    <div className='flex gap-3 border rounded-full items-center justify-between px-4 py-3'>
                        <IoIosSearch size={24} />
                        <input
                            className='w-full min-w-[515px] smsize:min-w-full flex'
                            placeholder='Search items'
                            onChange={(e) => handleChange(e.target.value)}
                        // value={input}
                        // value={storeProfileFilter?.title}
                        />
                    </div>
                </div>
                <div className='flex text-left justify-between items-center smsize:flex-col smsize:gap-5 smsize:items-start smsize:mb-5'>
                    <div className='text-[13px] uppercase smsize:text-[12px]'>
                        Found <strong>{storeProfileList.total}</strong> Local results {' '}
                        {storeProfileFilter?.title &&
                            <>
                                for <strong>"{storeProfileFilter.title}"</strong>
                            </>
                        }
                    </div>
                    <div className='smsize:text-[12px] flex items-center justify-between gap-1 border rounded-full px-2 py-1'>
                        <h3>Sort by:</h3>
                        <select className='border-none font-light'>
                            <option>Featured</option>
                        </select>
                    </div>
                </div>
                {/* <DealProducts business={business} /> */}
                {/* <NewItemsProducts business={business} /> */}
                <AllProducts business={business} isMobile={isMobile} />
            </div>
        </div>
    )
}
