/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * user's APIs
 * 
 * @author Yang Ming
 * @version Sept 26, 2023
 */
import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
    baseURL: Config.apiUrl + Config.apiPrefix,
    timeout: 60 * 1e3,
})

httpClient.interceptors.request.use(config => {
    // Perform logic before sending request
    config.headers['authorization'] = `${Config.apiKey} ` + localStorage.getItem('token')
    return config
})

/**
 * add unwanted items to user's list
 * @param {string} item_id the item's id 
 * @returns  {object} the response object
 */
export async function addUnwantedItems(item_id) {
    try {
        const res = await httpClient.post(`/user/unwantedItems`, {
            id: item_id
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

/**
 * remove a item from the unwanted items list
 * @param {string} item_id the item's id
 * @returns 
 */
export async function removeUnwantedItems(item_id) {
    try {
        const res = await httpClient.delete(`/user/unwantedItems/${item_id}`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

/**
 * get the unwanted items list
 * @returns unwanted item list
 */
export async function getUnwantedItems() {
    try {
        const res = await httpClient.get(`/user/unwantedItems`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function paymentCreditCard(data) {
    try {
        const res = await httpClient.post(`/user/paymentCreditCard`, data)
        // console.log('postMessage', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}