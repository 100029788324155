/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileInfo defines the store information section of the store profile. 
 * It contains the store's phone number, address and open hours.
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import StoreOpenHours from '../../itemDetail/components/StoreOpenHours'
import { FacebookEmbed } from 'react-social-media-embed'
import { Tabs } from 'antd'

/**
 * Renders the store profile information.
 * @param {Object} props - The component props.
 * @param {Object} props.business - The business object containing store information.
 * @returns {JSX.Element|null} The rendered store profile information component.
 */
export default function StoreProfileInfo({
  business,
  // businessHours,
}) {

  if (!business?.businessPhone && !business?.address)
    return null

  const items = [
    {
      key: '1',
      label: 'Store Information',
      children: <div className=' flex h-fit min-h-[200px] flex-1 flex-col gap-[10px] rounded-xl border border-black px-[20px] py-[20px]'>
        {/* <h4 className='text-[20px] font-medium'>Store Information</h4> */}
        <div
          className='grid w-fit gap-x-3 gap-y-1'
          style={{ gridTemplateColumns: 'min-content auto' }}
        >
          {business?.businessPhone && (
            <>
              <span className='text-[14px] font-medium'>Phone: </span>
              <span className='text-[14px] font-light'>
                {business.businessPhone}
              </span>
            </>
          )}
          {business?.address && (
            <>
              <span className='text-[14px] font-medium'>
                Address:{' '}
              </span>
              {/* auto break line */}
              <p className='w-full text-[14px] font-light' style={{
                wordBreak: 'break-word'
              }}>
                {business?.address}, {business?.city},{' '}
                {business?.province} {business?.postalCode}
              </p>
            </>
          )}
          {business?.openHours?.length > 0 && (
            <>
              <span className='text-[14px] font-medium'>Hours: </span>
              <StoreOpenHours openHours={business.openHours} />
            </>
          )}
        </div>
      </div>,
    },
    // {
    //   key: '2',
    //   label: 'Facebook Post',
    //   children:
    //     <div className='rounded-xl border border-black flex justify-center min-h-[400px] py-[20px]'>
    //       <FacebookEmbed url="https://www.facebook.com/TheFitzba/posts/505978491802219" width={450} />
    //     </div>
    // }
  ]

  return (
    <div className=' flex h-fit min-h-[200px] flex-1 flex-col gap-[10px] rounded-xl border border-black px-[20px] py-[20px]'>
        <h4 className='text-[20px] font-medium'>Store Information</h4>
        <div
          className='grid w-fit gap-x-3 gap-y-1'
          style={{ gridTemplateColumns: 'min-content auto' }}
        >
          {business?.businessPhone && (
            <>
              <span className='text-[14px] font-medium'>Phone: </span>
              <span className='text-[14px] font-light'>
                {business.businessPhone}
              </span>
            </>
          )}
          {business?.address && (
            <>
              <span className='text-[14px] font-medium'>
                Address:{' '}
              </span>
              {/* auto break line */}
              <p className='w-full text-[14px] font-light' style={{
                wordBreak: 'break-word'
              }}>
                {business?.address}, {business?.city},{' '}
                {business?.province} {business?.postalCode}
              </p>
            </>
          )}
          {business?.openHours?.length > 0 && (
            <>
              <span className='text-[14px] font-medium'>Hours: </span>
              <StoreOpenHours openHours={business.openHours} />
            </>
          )}
        </div>
      </div>
  )
}
