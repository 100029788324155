/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileMobile defines a component for the mobile version of a store profile.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import StoreProfileMobileTop from './StoreProfileMobileTop'
import StoreProfileMobileBottom from './StoreProfileMobileBottom'
import StoreProfileInfo from './StoreProfileInfo'
import StoreProfileBrand from './StoreProfileBrand'
import StoreProfileCategory from './StoreProfileCategory'
import StoreProfileSearchBar from './StoreProfileSearchBar'
import { Config } from '@Config'
import { Helmet } from 'react-helmet-async'
/**
 * A component for the mobile version of a store profile.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {Function} props.setFilterCategoryProfile - The function to set the category filter.
 * @param {Function} props.setFilterBrandProfile - The function to set the brand filter.
 * @param {Function} props.dispatch - The dispatch function from the Redux store.
 * @param {Object} props.business - The business object related to the store.
 * @param {Function} props.handleClickStore - The function to call when the store is clicked.
 */

export default function StoreProfileMobile({
  itemsFilter,
  setFilterCategoryProfile,
  setFilterBrandProfile,
  dispatch,
  business,
  handleClickStore,
}) {

  const [showInfo, setShowInfo] = React.useState(false)
  const [showDepartments, setShowDepartments] = React.useState(false)

  function handleClickBottomButton(name) {
    if (name === 'info') {
      setShowInfo(!showInfo)
      setShowDepartments(false)
    } else if (name === 'departments') {
      setShowDepartments(!showDepartments)
      setShowInfo(false)
    }
  }

  return (
    <div className='flex w-full flex-col'>
      <Helmet>
        <title>
          {business?.name} - {Config.appName}
        </title>
      </Helmet>
      <div className={`flex h-fit flex-col gap-5 pb-1`}>
        <div className={`flex-1 text-[14px]`}>
          <StoreProfileMobileTop
            isMobile={true}
            business={business}
            handleClickStore={handleClickStore}
          />
          <StoreProfileMobileBottom
            showInfo={showInfo}
            showDepartments={showDepartments}
            handleClickBottomButton={handleClickBottomButton}
          />
        </div>
      </div>
      {showInfo && (
        <StoreProfileInfo
          business={business}
        />
      )}
      {showDepartments && (
        <div className='flex h-fit flex-col gap-5 pb-1'>
          <StoreProfileBrand
            dispatch={dispatch}
            setFilterBrandProfile={setFilterBrandProfile}
            itemsFilter={itemsFilter}
            brands={business?.brands}
          />
          <StoreProfileCategory
            dispatch={dispatch}
            setFilterCategoryProfile={setFilterCategoryProfile}
            itemsFilter={itemsFilter}
            categories={business?.categories}
          />
        </div>
      )}

      <StoreProfileSearchBar
        business={business}
        itemsFilter={itemsFilter}
        isShow={true}
      />
    </div>
  )
}

