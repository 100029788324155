/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * ImageList.js exports a React component named 'ImageList'. This component displays a list of images for a specific item.
 * The user can click on the left or right arrows to navigate through the images. The currently displayed image is indicated by a border around the image in the list below the main image.
 * 
 * The component takes three props: 'images', 'name', and 'isUsed'. 'images' is an array of image URLs. 'name' is the name of the item. 'isUsed' is a boolean indicating whether the item is used.
 * 
 * @author Yang Ming
 * @version January 19th, 2024
 */

import React from 'react'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'
import ImagePreOwned from './ImagePreOwned'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

/**
 * A component that displays a list of images for a specific item.
 * 
 * @param {Object} props - The props object.
 * @param {Array} props.images - An array of image URLs.
 * @param {string} props.name - The name of the item.
 * @param {boolean} props.isUsed - A boolean indicating whether the item is used.
 * @returns {JSX.Element} The 'ImageList' component.
 */
export default function ImageList({
  imageIndex,
  setImageIndex,
  images,
  name,
  isUsed
}) {
  // const [imageIndex, setImageIndex] = useState(0)
  let image = images?.length > 0 ? images[imageIndex] : '/item-default.png'
  if (images.length > 0) {
    image = imageIndex < images.length ? images[imageIndex] : images[0]
  }
  console.log('imageIndex: ', imageIndex)
  return (
    <div className='flex flex-1 flex-col items-center pb-10 sm:pb-20'>
      <div className='relative flex items-center justify-center h-[300px] w-[300px] rounded-xl border border-[#000] border-opacity-10 sm:h-[500px] sm:w-[500px]'>
        <ImagePreOwned isUsed={isUsed} />
        <Zoom>
          <img
            src={image}
            alt={name}
            className='w-full h-[295px] sm:h-[495px] object-contain rounded-[10px]'
          />
        </Zoom>
        {images?.length > 1 &&
          <div className='absolute bottom-3 right-5 hidden flex-row gap-3 sm:flex'>
            <div
              className='flex h-[42px] w-[42px] cursor-pointer items-center justify-center rounded-full bg-[#f5f5f5] font-thin'
              onClick={() => {
                if (imageIndex - 1 < 0) {
                  setImageIndex(images.length - 1)
                } else {
                  setImageIndex(imageIndex - 1)
                }
              }}
            >
              <HiOutlineChevronLeft style={{ color: '#666' }} size={24} />
            </div>
            <div
              className='flex h-[42px] w-[42px] cursor-pointer items-center justify-center rounded-full bg-[#f5f5f5] font-thin'
              onClick={() => {
                if (imageIndex + 1 > Number(images.length) - 1) {
                  setImageIndex(0)
                } else {
                  setImageIndex(imageIndex + 1)
                }
              }}
            >
              <HiOutlineChevronRight style={{ color: '#666' }} size={25} />
            </div>
          </div>
        }
      </div>
      <div className='flex w-full sm:pt-2 imglist-icons-grid'>
        {images?.map((item, index) => (
          <div
            key={index}
            className='h-14 w-14 rounded-[10px] sm:h-20 sm:w-20 cursor-pointer'
            onClick={() => {
              setImageIndex(index)
            }}
          >
            <img
              src={item}
              alt={name}
              className={`h-full border rounded-[10px] w-full object-contain ${imageIndex === index && 'border-[#E4B456]'}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

