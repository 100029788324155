/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * ShoppingCart is a functional React component that displays the user's shopping cart.
 * It shows a list of items in the cart, the subtotal, and a button to continue to the summary.
 * If the cart is empty, it shows a message indicating that the cart is empty.
 * Additionally it provides a button to continue shopping, which redirects the user to the search page.
 * The user can also update or delete items in the cart.
 *
 * @author James Nguyen, Yang Ming
 * @version October 13th, 2023
 */

import React, { useState } from 'react'
import './ShoppingCart.css'
import CartEmpty from './components/CartEmpty'
import CardSummary from './components/CartSummary'
import CardItemList from './components/CartItemList'
import { useSelector, useDispatch } from 'react-redux'
import { formatCurrency } from '@utils/Helper'
import { setGotoElement } from '@reducers/contentReducer'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { Link } from 'react-router-dom'
import { AiOutlineLeft } from 'react-icons/ai'
import CartReservation from './components/CartReservation'

export default function ShoppingCart() {
  const shoppingCart = useSelector(state => state.user.shoppingCart)
  const location = useSelector(state => state.user.location)
  const [showPopup, setShowPopup] = useState(false)
  const [selectedStore, setSelectedStore] = useState('')
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.content.isMobile)

  const userinfo = useSelector(state => state.user.userinfo)
  const { updateCart, deleteCart } = ShoppingCartHook()
  const cartData = shoppingCart?.data?.data?.filter(
    item => item.business.name === selectedStore,
  )[0]

  console.log('shoppingCart', shoppingCart)

  if (
    shoppingCart.data === null ||
    shoppingCart.data?.status === 'error' ||
    shoppingCart.data?.totalItems === 0
  )
    return (
      <div className='cart-wrapper'>
        <CartEmpty />
      </div>
    )

  return (
    <div className='cart-wrapper'>
      {showPopup && (
        <CartReservation
          userinfo={userinfo}
          isMobile={isMobile}
          cartData={cartData}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      )}
      <div className='cart-main'>
        <div className='cart-content'>
          <div className='cart-content-header'>
            <Link to='/search' className='cart-button-continue'>
              <AiOutlineLeft /> Continue shopping
            </Link>
            <h3>Your Shopping List</h3>
            <p>
              The items in your <strong>LIST</strong> are available for in-store
              pay and pick-up only. <br />
              Here's a <strong>SHOPPING LIST</strong> to help you plan your
              shopping trip.
            </p>
          </div>
          <div className='cart-content-continue'>
            <div>
              <h4>Subtotal</h4>
              <p>{formatCurrency(shoppingCart?.data?.subTotal)}</p>
            </div>
            <button
              onClick={() => {
                dispatch(setGotoElement('cart-summary'))
              }}
            >
              Continue
            </button>
          </div>
          <CardItemList
            isMobile={isMobile}
            setSelectedStore={setSelectedStore}
            shoppingCart={shoppingCart}
            location={location}
            setShowPopup={setShowPopup}
            handleUpdateCart={updateCart}
            handleDeleteCart={deleteCart}
          />
        </div>
        <CardSummary shoppingCart={shoppingCart} />
      </div>
    </div>
  )
}
