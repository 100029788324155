import React, { useEffect, useState } from 'react'
import { debounce, set } from 'lodash'
import FilterSideSearchBar from '../../zearch/components/FilterSideSearchBar'
import FilterSideSectionHeader from '../../zearch/components/FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'
import { useSelector } from 'react-redux'
import { getStoreBrandList } from 'services/api.business.service'

const DEFAULT_SHOW_NUMBER = 10

export default function FilterSideBrand({
  business,
  itemsFilter,
  dispatch,
  filterList,
  setFilterBrand
}) {

  const [input, setInput] = useState('')
  // const [isOpen, setIsOpen] = useState(false)
  const isOpen = useSelector(state => state.user.filterState.brand)
  const isMobile = useSelector(state => state.content.isMobile)
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'brand', value }))
  }
  const [showNumber, setShowNumber] = useState(100)
  const [brands, setBrands] = useState([])
  const [allCheckbox, setAllCheckbox] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const res = await getStoreBrandList({
        storeid: business?._id
      })
      console.log('res', res)
      setBrands(res.data || [])
      setShowNumber(res.data.length || DEFAULT_SHOW_NUMBER)
    }
    fetchData()
  }, [business?._id, dispatch])


  function onClickTitle() {
    // defaultSetting()
    setIsOpen(!isOpen)
  }

  function defaultSetting() {
    setInput('')
    setShowNumber(DEFAULT_SHOW_NUMBER)
    setBrands([])
  }

  const handleChange = debounce((value) => {
    console.log('onSearch', value)
    // search function
    const findList = filterList?.brands.filter(item => {
      // startswith or each words startswith if the name is contain splace
      if (!item.title)
        return false
      const words = item.title.indexOf(' ') !== -1 ? item.title.toLowerCase().split(' ') : [item.title.toLowerCase()]
      const inputLower = value.toLowerCase()
      const result = words.find(item => item.startsWith(inputLower))
      // console.log('result', result)
      return result
    })
    setShowNumber(filterList?.brands?.length)
    setBrands(findList)
  }, 600)

  const onSearch = (e) => {
    const value = e.target.value
    setInput(value)
    if (!value) {
      // defaultSetting()
      return
    }
    handleChange(value)
  }

  function onClickAllCheckbox(checked) {
    console.log('onClickAllCheckbox', checked)
    // const checked = e.target.checked
    if (checked) {
      // select all
      const ids = filterList?.brands.map(item => item.id)
      dispatch(setFilterBrand({ id: ids, checked: true }))
    } else {
      // deselect all
      // const ids = filterList?.brands.map(item => item.id)
      dispatch(setFilterBrand(null))
    }
    setAllCheckbox(checked)
  }

  return (
    <div className='filter-item' style={{
      color: '#000'
    }}>
      <FilterSideSectionHeader
        title='Brand'
        isOpen={isOpen}
        onClickTitle={onClickTitle}
        isHighlight={itemsFilter?.brands?.length > 0}
        color='#000'
      />
      <FilterSideSearchBar
        input={input}
        isOpen={isOpen}
        onSearch={onSearch}
        onClear={defaultSetting}
        allCheckbox={allCheckbox}
        onSelectAll={() => onClickAllCheckbox(!allCheckbox)}
        onClearAll={() => onClickAllCheckbox(false)}
      />
      <div className={`filter-item-list smsize:gap-2 smsize:text-[16px]`} style={{ display: isOpen ? 'flex' : 'none' }}>
        {brands.slice(0, DEFAULT_SHOW_NUMBER).map((brand) => {
          return (
            <div key={brand._id} className='filter-checkbox-item text-black'>
              <label>
                <input
                  value={brand._id}
                  type='checkbox'
                  className={`custom-checkbox`}
                  checked={itemsFilter?.brands.includes(brand._id)}
                  onChange={e => {
                    setFilterBrand({
                      _id: brand._id,
                      checked: e.target.checked,
                    })
                  }}
                />
                <span className={`truncate ${isMobile && 'text-[16px]'}`}>{brand.title}</span>
              </label>
            </div>
          )
        })}
      </div>
      {/* {filterList?.brands.length > DEFAULT_SHOW_NUMBER && */}
      {((input !== '' && brands.length > DEFAULT_SHOW_NUMBER) || (input === '' && filterList?.brands.length > DEFAULT_SHOW_NUMBER)) &&
        <div className='filter-checkbox-item underline' style={{ display: isOpen ? 'flex' : 'none' }}>
          <span onClick={() => setShowNumber(showNumber === DEFAULT_SHOW_NUMBER ? brands.length : DEFAULT_SHOW_NUMBER)}>
            Show {showNumber === DEFAULT_SHOW_NUMBER ? 'More' : 'Less'} Brands ({brands.length})
          </span>
        </div>
      }
    </div>
  )
}
