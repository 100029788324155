/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileSearchBar implements a search feature within the store profile.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 15th, 2024
 */

import React from 'react'
// import { BiSearchAlt2 } from 'react-icons/bi'
import { IoIosSearch } from 'react-icons/io'
import { debounce } from 'lodash'
import { setStoreProfileSearch } from '@reducers/contentReducer'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import './StoreProfileSearchBar.css'

/**
 * Component for the search bar in the store profile.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.business - The business object related to the store.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {boolean} props.isShow - A flag indicating whether the search bar should be shown.
 */

export default function StoreProfileSearchBar({
  business,
  itemsFilter,
  isShow,
}) {
  const dispatch = useDispatch()
  const [input, setInput] = React.useState('')
  const [isShowStoreSearch, setIsShowStoreSearch] = React.useState(
    isShow || false,
  )
  const isMobile = useMediaQuery({ query: '(max-width: 630px' })
  const containerStyle = isMobile ? 'mt-4' : ''

  const handleChange = debounce(
    value => {
      dispatch(
        setStoreProfileSearch({
          title: value,
          store: business._id,
        }),
      )
    },
    1000,
    [],
  )

  const onChange = e => {
    setInput(e.target.value)
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleChange(input)
    }
  }

  return (
    <div className={`flex items-center justify-center ${containerStyle}`}>
      {isShowStoreSearch ? (
        <div className='store-profile-search-bar store-searchbar-input'>
          <IoIosSearch size={30} />
          <input
            autoFocus='autoFocus'
            value={input}
            onChange={onChange}
            onKeyDown={handleKeyPress}
            placeholder='Search in the store'
            className='font-[200px]'
          />
          <button
            className='h-7 w-7'
            onClick={() => {
              handleChange(input)
            }}
          />
         
        </div>
      ) : (
        <button
          className='flex h-[40px] w-[40px] items-center justify-center rounded-full border-[1px] border-solid border-black transition-transform duration-150 ease-in-out hover:scale-105 hover:bg-[#D9D9D9] hover:text-black phone:text-base'
          onClick={() => {
            setIsShowStoreSearch(!isShowStoreSearch)
          }}
        >
          <IoIosSearch size={24} />
        </button>
      )}
    </div>
  )
}
