import React from 'react'
import StoreGoogleMap from '../itemDetail/components/StoreGoogleMap'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import '../itemDetail/components/StoreGoogleMap.css'
import { GoogleMap, Marker } from "@react-google-maps/api"

export default function StoreAbout({
    isMobile,
    business,
    location
}) {
    return (
        <div className='w-full py-10'>
            <div className='m-auto w-full max-w-[1280px] flex justify-between gap-10 smsize:max-w-full smsize:flex-col smsize:p-5 smsize:gap-5'>
                <div className='flex-1 text-left flex flex-col gap-3'>
                    <h3 className='text-[32px] font-medium uppercase'>About us</h3>
                    <p className='text-[14px] leading-4' dangerouslySetInnerHTML={{ __html: business.description }}></p>
                    <div className='hover:underline cursor-pointer'>see more</div>
                </div>
                <div className='flex-1'>
                <div className='flex flex-col items-center justify-center gap-2' >
                    <GoogleMap
                        mapContainerStyle={{
                            width: "500px",
                            height: "400px",
                            borderRadius: '22px',
                            borderBottom: '2px solid #CDCDCD'
                        }}
                        center={location}
                        zoom={15}
                    // onClick={() => { }}
                    >
                        {location && <Marker position={location} label={{ text: `${business?.name}`, className: 'marker-label' }} />}
                    </GoogleMap>
                    <a href={business?.mapUrl ? business.mapUrl : `https://maps.google.com/?q=${business?.lat},${business?.lng}`} alt={business?.name} target='_blank' rel="noreferrer"
                        className='text-[#0B6186] font-light underline text-center mb-3'>
                        Get Directions
                    </a>
                </div>
                    {/* <img className='border rounded-2xl' src="/images/store/map.png" alt="store map" /> */}
                </div>
            </div>
            {/* <div className='bg-[#F4F4F4] w-full py-10 smsize:p-3'>
                <div className='flex flex-col items-center justify-center gap-3'>
                    <div>
                        <img className='rounded-[35px]' src="/images/store/about.png" alt="store about" />
                    </div>
                    <div className='flex gap-3 smsize:flex-wrap'>
                        <img className='rounded-[10px] smsize:w-[100px] smsize:h-[100px]' src="/images/store/about-small.png" alt="store about" />
                        <img className='rounded-[10px] smsize:w-[100px] smsize:h-[100px]' src="/images/store/about-small.png" alt="store about" />
                        <img className='rounded-[10px] smsize:w-[100px] smsize:h-[100px]' src="/images/store/about-small.png" alt="store about" />
                        <img className='rounded-[10px] smsize:w-[100px] smsize:h-[100px]' src="/images/store/about-small.png" alt="store about" />
                    </div>
                </div>
            </div> */}
            {/* <div className='w-full flex flex-col items-center justify-center gap-5 py-5'>
                <h3 className='uppercase text-[32px] smsize:text-[20px]'>proud supporters of</h3>
                <div className=' flex items-center flex-wrap justify-center gap-7'>
                    <img className='w-[135px] h-[135px] rounded-full smsize:w-[85px] smsize:h-[85px]' src="/images/store/supporter.png" alt="supporter" />
                    <img className='w-[135px] h-[135px] rounded-full smsize:w-[85px] smsize:h-[85px]' src="/images/store/supporter.png" alt="supporter" />
                    <img className='w-[135px] h-[135px] rounded-full smsize:w-[85px] smsize:h-[85px]' src="/images/store/supporter.png" alt="supporter" />
                    <img className='w-[135px] h-[135px] rounded-full smsize:w-[85px] smsize:h-[85px]' src="/images/store/supporter.png" alt="supporter" />
                    <img className='w-[135px] h-[135px] rounded-full smsize:w-[85px] smsize:h-[85px]' src="/images/store/supporter.png" alt="supporter" />
                    <img className='w-[135px] h-[135px] rounded-full smsize:w-[85px] smsize:h-[85px]' src="/images/store/supporter.png" alt="supporter" />
                </div>
            </div> */}
        </div>
    )
}
