import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Radio, message, Space, Checkbox, Switch, InputNumber } from 'antd'
import { isCreditCardValid, isExpiryDateValid, isCVCValid } from '@utils/Helper'
import { paymentCreditCard } from '@services/api.user.service'
import { useSelector } from 'react-redux'

export default function RegisterStep3({
    setCurrent
}) {
    const userinfo = useSelector(state => state.user.userinfo)
    const business_id = useSelector(state => state.user.business_id)
    const [formRef] = Form.useForm()
    const [isCustomPricing, setIsCustomPricing] = useState(false)
    const [isSetupPayment, setIsSetupPayment] = useState(false)
    const [planAmount, setPlanAmount] = useState(50)

    useEffect(() => {
        if (formRef) {
            formRef.setFieldsValue({
                planAmount: 50,
                planType: 'monthly'
            })
        }
    }, [formRef])

    function onClickPlanType(e) {
        const value = e.target.value
        let amount = 0
        if (value === 'freeTrial') {
            amount = 0
        } else if (value === 'monthly') {
            amount = 50
        } else if (value === 'yearly') {
            amount = 500
        }
        setPlanAmount(amount)
        if (formRef) {
            formRef.setFieldsValue({
                planAmount: amount
            })
        }
    }

    const checkCardNumber = async (_, cardNumber) => {
        // return Promise.resolve()

        if (!cardNumber) {
            return Promise.resolve()
        }
        // return true
        if (!isCreditCardValid(cardNumber)) {
            throw new Error('Card number is invalid')
        }
    }

    const checkExpireDate = async (_, expireDate) => {
        if (!expireDate) {
            return Promise.resolve()
        }
        if (expireDate.length < 4 || expireDate.length > 5) {
            throw new Error('Expire date is invalid')
        }
        if (!isExpiryDateValid(expireDate)) {
            throw new Error('Expire date is invalid')
        }
    }

    const checkCvv = async (_, cvv) => {
        if (!cvv) {
            return Promise.resolve()
        }
        if (!isCVCValid(cvv)) {
            throw new Error('CVV code is invalid')
        }
    }

    const onFinish = async (values) => {
        try {
            if (values.planType !== 'free' && values?.planAmount <= 0) {
                message.error('The amount must be greater than 0')
                return
            }
            console.log('business_id', business_id)
            const res = await paymentCreditCard({
                ...values,
                planAmount: values.planAmount || planAmount,
                user_id: userinfo._id,
                business_id: business_id,
                isCustom: isCustomPricing
            })
            console.log('onFinish', res)
            message.success('Launch offer setup successfully!')
            setCurrent(3)
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Launch offer failed, please try again')
        }
    }

    return (
        <div className='py-8 m-auto w-[400px]'>
            <Form
                form={formRef}
                name="createNewStore"
                style={{
                    maxWidth: 500,
                    textAlign: 'left',
                }}
                onFinish={onFinish}
                layout='vertical'
                autoComplete="off"
            >
                <Form.Item
                    name="planType"
                    label="Plan Type"
                    initialValue={'free'}
                    rules={[
                        {
                            required: true,
                            message: 'Please select a plan type',
                        },
                    ]}
                    onChange={onClickPlanType}
                >
                    <Radio.Group>
                        <Radio.Button value="free">Free Trial</Radio.Button>
                        <Radio.Button value="monthly">Monthly</Radio.Button>
                        <Radio.Button value="yearly">Yearly</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Space>
                    <Form.Item name="planAmount"
                        label="Amount"
                        rules={[
                            {
                                required: true,
                                message: 'Amount is required',
                            }
                        ]}
                        style={{
                            width: '120px'
                        }}

                    >

                        <InputNumber
                            addonBefore='$'
                            onChange={(e) => {
                                setPlanAmount(e)
                            }}
                            disabled={!isCustomPricing}
                        />
                    </Form.Item>
                    <Checkbox onChange={(e) => {
                        setIsCustomPricing(e.target.checked)
                    }}>
                        I'd like to custom the price
                    </Checkbox>
                </Space>
                <Form.Item name="setPayment" label="Setup Payment" valuePropName='checked' >
                    <Switch onChange={(v) => {
                        setIsSetupPayment(v)
                    }}
                        className=' bg-gray-300'
                    />
                </Form.Item>
                {isSetupPayment && (
                    <div>
                        <h3 className='mb-2 text-[22px] font-[300]'>Payment method</h3>
                        <Form.Item
                            name='cardName'
                            label='Name on card'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input card name',
                                },
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder='Name on card'
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name='cardNumber'
                            label='Card number'
                            rules={[
                                {
                                    validator: checkCardNumber,
                                },
                                {
                                    required: true,
                                    message: 'Please input card number',
                                },
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder='Card number'
                                suffix={<img src='/images/credit-icon.png' alt='' />}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            style={{
                                marginBottom: 0,
                            }}
                        >
                            <Form.Item
                                name='cardExpireDate'
                                rules={[
                                    {
                                        validator: checkExpireDate,
                                    },
                                    {
                                        required: true,
                                        message: 'Please input expiry date',
                                    },
                                ]}
                                style={{
                                    display: 'inline-block',
                                    width: 'calc(50% - 8px)',
                                    marginRight: '16px',
                                }}
                            >
                                <Input
                                    size='large'
                                    placeholder='MM/YY'
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                name='cardCvv'
                                rules={[
                                    {
                                        validator: checkCvv,
                                    },
                                    {
                                        required: true,
                                        message: 'Please input cvv',
                                    },
                                ]}
                                style={{
                                    display: 'inline-block',
                                    width: 'calc(50% - 8px)',
                                }}
                            >
                                <Input
                                    size='large'
                                    placeholder='CVV'
                                    allowClear
                                />
                            </Form.Item>
                        </Form.Item>
                    </div>
                )}
                <Form.Item style={{
                    marginBottom: 0,
                }}>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                        marginRight: '16px',
                    }}>
                        <Button
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white py-2 px-5 text-black hover:bg-slate-200'
                            onClick={() => setCurrent(1)}
                        >
                            Previous
                        </Button>
                    </Form.Item>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                    }}>
                        <Button
                            htmlType='submit'
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-black py-2 px-5 text-white hover:bg-slate-200'
                        >
                            Continue
                        </Button>
                    </Form.Item>
                </Form.Item>
            </Form>
        </div>
    )
}
