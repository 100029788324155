import React, { useState } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { Config } from '@Config'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

export default function RegisterStep4({
    setCurrent,
}) {
    const userinfo = useSelector(state => state.user.userinfo)
    const [calendlyLink, setCalendlyLink] = useState('')

    useCalendlyEventListener({
        onEventScheduled: (e) => {
            console.log('calendly event scheduled', e)
            setCalendlyLink(e.data?.payload?.event?.uri)
            setCurrent(4)
        },
    })

    return (
        <div className='m-auto'>
            {/* <p className='text-[30px] font-light'>Meet with a Fitzba team member <br />to set up your account</p> */}
            <div className='w-full overflow-hidden'>
                <InlineWidget
                    // url='https://calendly.com/alana-fitzba/intro-call'
                    url={Config.calendlyUrl}
                    // HOOG377CRRYQHP3RKT2JCDWVJWWXQ5MJ
                    // eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE1NjYzNjU2LCJqdGkiOiI3M2JiODE1Yi01NGQ2LTQwYjAtYjM0My0xMzExMWE2MTg4OTgiLCJ1c2VyX3V1aWQiOiJlODFhNWRlMi1lYjNkLTRmOGQtYjBiNC1iMjEyNGViZWY0ZTgifQ.kbp-xWR0rsy27rfrF0SVIHIF_atVF1b0cYC6RD7FKrBH_p7HDpDjKIETtIFUuwoR1K7CttLH7kUWcjDiTLJ4Iw
                    styles={{ overflow: 'hidden', height: '700px' }}
                    prefill={{
                        name: userinfo?.firstName + ' ' + userinfo?.lastName,
                        email: userinfo?.username,
                        smsReminderNumber: userinfo?.phone,
                        customAnswers: {
                            a1: "Fitzba Store Setup",
                        }
                    }}
                    pageSettings={{
                        // hideEventTypeDetails: true,
                        // hideLandingPageDetails: true,
                    }}
                />
            </div>
            <div className='w-full flex items-center justify-center mb-5'>
                <Button
                    htmlType='button'
                    className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white py-2 px-5 text-black hover:bg-slate-200'
                    onClick={() => setCurrent(2)}
                >
                    Previous
                </Button>
            </div>
        </div>
    )
}
