/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Profile is a functional React component that displays a form for the user to view and edit their profile information.
 * The form includes fields for email, first name, last name, phone number, and postal/zip code.
 * The user can also change their password or delete their account.
 * The form data is sent to the server when the form is submitted.
 *
 * @author Yang Ming, James Nguyen
 * @version January 4th, 2024
 */

import { Avatar, Form, Input, Tooltip, Modal, message } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './Profile.css'
import {
  changePassword,
  changeProfile,
  deleteUser,
} from '../../services/api.service'
import { getLatLngAndAddressFromPostalCode } from '@services/google.map.service'
import { isValidPostalCodeCAandUS } from '../../utils/Helper'
import InputSuffixTip from '../user/components/InputSuffixTip'
import { logout } from '../../reducers/userReducer'
import { ExclamationCircleFilled } from '@ant-design/icons'
import LoadingSection from '../../components/LoadingSection'
const { confirm } = Modal

const Profile = () => {
  const userinfo = useSelector(state => state.user.userinfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  if (!userinfo) {
    navigate('/')
  }
  const [formDisabled, setFormDisabled] = useState(true)
  const [showPassChange, setShowPassChange] = useState(false)
  const [form] = Form.useForm()

  const checkPostalCode = async (_, postalCode) => {
    if (!postalCode) {
      return Promise.resolve()
    }
    if (!isValidPostalCodeCAandUS(postalCode)) {
      throw new Error('Postal code is invalid')
    }
  }

  const handleDeleteAccount = async id => {
    console.log(id)

    await deleteUser(id)
    dispatch(logout())
    navigate('/')
  }

  const handleSubmit = async values => {
    try {
      // console.log('handleSubmit', values)
      let latlng = {
        lat: 0,
        lng: 0,
      }
      if (values.postalCode) {
        const res = await getLatLngAndAddressFromPostalCode(values.postalCode)
        if (res) {
          latlng = {
            lat: res?.lat,
            lng: res?.lng,
          }
        }
      }
      // Get latitude and longitude from postal code
      await changeProfile(userinfo?._id, {
        ...values,
        ...latlng,
      })
      setFormDisabled(true)
      message.success('Profile Changed')
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1500)
    } catch (err) {
      console.error('handleSubmit', err)
      message.error('Action failed: ' + err.data?.message || 'Please try again!')
    }
  }

  const handleChangePass = async values => {
    console.log('handleChangePass', values)
    try {
      await changePassword(userinfo?._id, values)
      message.success('Password Changed')
      setShowPassChange(false)
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1500)
    } catch (err) {
      console.error('handleChangePass', err)
      message.error('Action failed: ' + err.data?.message || 'Please try again!')
    }
  }
  if (!userinfo) {
    return <LoadingSection text='Loading Stores' />
  }

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this account?',
      icon: <ExclamationCircleFilled />,
      content: 'This action in not reversible',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDeleteAccount(userinfo?._id)
      },
    })
  }

  return (
    <div className='profile my-6 flex h-full w-full flex-col items-center'>
      <div className='mt-10 mb-10 h-[150px] w-full'>
        <Avatar
          className='profile-avatar  cursor-pointer bg-black align-middle text-[80px] font-normal text-white'
          style={{ fontSize: '80px' }}
          size={110}
        >
          {userinfo?.firstName[0]?.toUpperCase()}
        </Avatar>
      </div>
      {!showPassChange ? (
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 30,
          }}
          form={form}
          layout='horizontal'
          onFinish={handleSubmit}
          disabled={formDisabled}
          className='profile-form'
        >
          <Tooltip
            placement='right'
            title='Can not change this field as this is a unique identifier, please contact us if you want your email changed.'
          >
            <Form.Item
              name='username'
              label='Email'
              initialValue={userinfo?.username}
              rules={[
                { required: true, message: 'Please input your email' },
                {
                  min: 10,
                  message: 'Please enter a valid email',
                  regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                },
              ]}
            >
              <Input value={userinfo?.username} disabled />
            </Form.Item>
          </Tooltip>
          <Form.Item
            name='firstName'
            label='First Name'
            initialValue={userinfo?.firstName}
            rules={[
              { required: true, message: 'Please input your first name' },
            ]}
          >
            <Input value={userinfo?.firstName} />
          </Form.Item>
          <Form.Item
            name='lastName'
            label='Last Name'
            initialValue={userinfo?.lastName}
            rules={[{ required: true, message: 'Please input your last name' }]}
          >
            <Input value={userinfo?.lastName} />
          </Form.Item>

          <Form.Item
            name='phone'
            label='Phone'
            initialValue={userinfo?.phone}
            rules={[
              { required: true, message: 'Please input your phone number' },
              {
                max: 12,
                min: 12,
                message: 'The format is ###-###-####',
                pattern: /^\d{3}-\d{3}-\d{4}$/,
              },
            ]}
          >
            <Input
              size='large'
              suffix={<InputSuffixTip text='The format is ###-###-####' />}
              placeholder='###-###-####'
              onChange={e => {
                const value = e.target.value
                if (value.length === 10 && /^\d+$/.test(value)) {
                  const text =
                    value.slice(0, 3) +
                    '-' +
                    value.slice(3, 6) +
                    '-' +
                    value.slice(6)
                  form.setFieldsValue({
                    phone: text,
                  })
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name='postalCode'
            label='Postal/Zip Code'
            initialValue={userinfo?.postalCode}
            rules={[
              { validator: checkPostalCode },
              {
                required: true,
                message: 'Please input your Postal/Zip Code',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {formDisabled ? (
            <div className='flex flex-row justify-end gap-5'>
              <div
                name='changePass'
                className='mt-2 flex w-fit cursor-pointer justify-center rounded-full  border-2 border-red-500 bg-red-500 px-4 py-1 text-base text-white hover:bg-red-700 '
                onClick={showDeleteConfirm}
              >
                Delete Account
              </div>
              <div
                name='changePass'
                className='mt-2 flex w-fit cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-4 py-1 text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                onClick={() => {
                  setShowPassChange(true)
                }}
              >
                Change Password
              </div>
              <div
                name='editProfile'
                className='mt-2 flex w-fit cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-4 py-1 text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                onClick={() => {
                  setFormDisabled(false)
                }}
              >
                Edit
              </div>
            </div>
          ) : (
            <div className='flex flex-row justify-end gap-5'>
              <div
                className='mt-2 flex w-fit cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static  bg-white px-4 py-1 text-base text-fitzba-bright-gold-static hover:border-black hover:text-black'
                onClick={() => {
                  setFormDisabled(true)
                }}
              >
                Cancel
              </div>
              <div className='mt-2 flex w-fit cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-4 py-1 text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'>
                <button type='submit'>Submit</button>
              </div>
            </div>
          )}
        </Form>
      ) : (
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 30,
          }}
          layout='horizontal'
          onFinish={handleChangePass}
          className='profile-form'
        >
          <Form.Item
            name='currentPass'
            label='Current password'
            rules={[
              {
                required: true,
                message: 'Please input your current password',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name='newPass'
            label='New Password'
            rules={[
              { required: true, message: 'Please input your new password' },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name='confirmPass'
            label='Confirm Password'
            rules={[
              { required: true, message: 'Please conffirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPass') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Passwords do not match'))
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className='flex flex-row justify-end gap-5'>
            <div
              className='mt-2 flex w-fit cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static  bg-white px-4 py-1 text-base text-fitzba-bright-gold-static hover:border-black hover:text-black'
              onClick={() => {
                setShowPassChange(false)
              }}
            >
              Cancel
            </div>
            <div className='mt-2 flex w-fit cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-4 py-1 text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'>
              <button type='submit'>Submit</button>
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}

export default Profile
