/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileLeft defines the left section of the store profile component.  
 * It contains user's name, email and phone number fields. 
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import { Link } from 'react-router-dom'
import StoreProfileLogo from './StoreProfileLogo'
import { getStoreLink } from '@utils/Helper'
import ShareButtons from 'pages/itemDetail/components/ShareButtons'

export default function StoreProfileLeft({
    isMobile,
    business,
    handleClickStore
}) {

    const link = getStoreLink(business?.shortLink, business?.city, business?.province)

    const className = isMobile ? 'mt-5 flex max-h-[180px] w-28 flex-col font-lexend md:max-h-full md:w-44' : 'mt-5 flex max-h-fit w-44 flex-col font-lexend'
    return (
        <div className={className}>
            <div className='h-28 w-28 rounded-full border-black bg-white md:h-40 md:w-40'>
                <StoreProfileLogo
                    logo={business?.logo}
                    name={business?.name}
                    link={link}
                    handleClickStore={handleClickStore}
                />
            </div>
            <div className='store_name truncate-multiline' style={{ textAlign: 'left' }}>
                <Link onClick={handleClickStore} to={link}>
                    <p>{business?.name}
                        {/* {business?.isVerified && (
                            <BsPatchCheckFill className='ml-1 inline-block text-black' />
                        )} */}
                    </p>
                </Link>
            </div>
            {business?.distance &&
                <div className='text-[11px] lg:text-[16px]'>
                    {parseFloat(business.distance).toFixed(1)} km
                </div>
            }
            <ShareButtons />

        </div>
    )
}
