/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This file contains the HeaderShoppingCartIcon component, which is responsible for displaying the shopping cart icon in the header.
 * The component uses Redux's useDispatch and useSelector hooks for state management and services from api.shoppingCart.service.
 * The component fetches the shopping cart data from the server and updates the Redux store with the fetched data.
 * The fetch operation is performed whenever the shoppingCart.isRefresh flag is set to true.
 * The component renders a shopping cart icon with a link to the shopping cart list. If there are selected items in the shopping cart, it also displays the number of selected items.
 *
 * @author Yang Ming
 * @version January 4th, 2024
 */

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getShoppingCartList } from '@services/api.shoppingCart.service'
import { setCartData, setCartRefresh } from '@reducers/userReducer'
import { BsCart3 } from 'react-icons/bs'

export default function HeaderShoppingCartIcon() {
    const dispatch = useDispatch()
    const shoppingCart = useSelector(state => state.user.shoppingCart)

    useEffect(() => {
        if (!shoppingCart.isRefresh) return
        const fetchShoppingCartList = async () => {
            try {
                const res = await getShoppingCartList()
                // console.log('fetchShoppingCartList', res)
                dispatch(setCartData(res))
                dispatch(setCartRefresh(false))
            } catch (error) {
                console.error('fetchShoppingCartList failed: ', error?.data?.message)
            }
        }
        fetchShoppingCartList()
    }, [dispatch, shoppingCart])

    return (
        <div className='relative pl-5 right-[10px]'>
            <Link to='/list'>
                <BsCart3 size={29} />
                {/* <img src='/images/order.png' alt='shopping cart' className='text-white max-w-[30px] max-h-[30px]' /> */}
                {shoppingCart?.data &&
                    shoppingCart?.data?.selectedItems > 0 && (
                        <div className='absolute -top-1 -right-1 flex h-[18px] w-[18px] text-[12px] items-center justify-center rounded-full bg-fitzba-bright-gold-static text-fitzba-black-static'>
                            {shoppingCart?.data?.selectedItems}
                        </div>
                    )}
            </Link>
        </div>
    )
}
