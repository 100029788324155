/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * StoreProfileCategories.js contains the StoreProfileCategory component, which is responsible for displaying and managing the categories of a store's profile.
 * The component uses the useDispatch and useSelector hooks from Redux for state management.
 * The setFilterCategory function is used to update the Redux store with the selected category.
 * The component renders a list of categories that can be expanded or collapsed.
 * Each category can be clicked to select it and update the Redux store.
 * The component also includes a button to clear the selected category.
 *
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { setFilterCategory } from '@reducers/contentReducer'

/**  
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.dispatch - The Redux dispatch function.
 * @param {Function} props.setFilterCategoryProfile - The function to set the selected category.
 * @param {Object} props.itemsFilter - The current items filter.
 * @param {Array} props.categories - The list of categories.
 **/

export default function StoreProfileCategory({
  dispatch,
  setFilterCategoryProfile,
  itemsFilter,
  categories,
}) {
  const isMobile = useSelector(state => state.content.isMobile)
  const [isOpen, setIsOpen] = React.useState(!isMobile)

  if (!categories || categories.length === 0) return null

  function onClick() {
    setIsOpen(!isOpen)
  }

  function onClickClear() {
    dispatch(setFilterCategory(null))
  }

  categories.sort((a, b) => {
    if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
      return -1
    }
    if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
      return 1
    }
    return 0
  })

  return (
    <div className=' flex max-h-[180px] flex-1 flex-col gap-[10px] rounded-xl border border-[#D9D9D9] @container'>
      <div
        className={`flex h-fit w-full items-center justify-between cursor-pointer ${!isOpen ? 'rounded-xl' : 'rounded-t-xl'
          } bg-[#F3F3F3] px-[10px] py-[5px] text-[20px] font-medium sm:px-[20px]`}

      >
        <h3 onClick={onClick}>Categories</h3>
        <div className='flex items-center justify-center gap-3'>
          {itemsFilter.categories.length > 0 && <button className='filter-selected-clear-all' onClick={onClickClear}>Clear All</button>}
          <span onClick={onClick}>
            {isOpen ? <AiOutlineUp /> : <AiOutlineDown />}
          </span>
        </div>
      </div>
      {isOpen && (
        <div className='show-scroll grid grid-cols-1 gap-x-[17px] gap-y-[19px] overflow-y-scroll rounded-b-xl px-[10px] pb-[20px] @sm:px-[20px]'>
          {categories.map(category => (
            // <Tooltip title={category.label} arrow placement='top' key={category._id}>
            <div
              key={category._id}
              className={` flex h-[31px] w-full items-center truncate rounded-md cursor-pointer border ${itemsFilter.categories.find(id => id === category._id)
                ? 'border-[#E4B456]'
                : 'border-[#D9D9D9]'
                } px-2 text-[13px] hover:bg-[#D9D9D9]`}
              onClick={() => {
                dispatch(setFilterCategoryProfile(category))
                // console.log(brand)
              }}
            >
              <span className='w-full truncate'>
                {category.label}
              </span>
            </div>
            // </Tooltip>
          ))}
        </div>
      )}
    </div>
  )
}
