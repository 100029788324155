/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideCategory defines a component that allows users to filter by delivery method
 * 
 * @author Yang Ming
 * @version January 15th, 2024 
 */

import React from 'react'
// import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { deliveryMethod } from '@data/filter.data'
import FilterSideSectionHeader from './FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'
import { useSelector } from 'react-redux'

/**
 * A component for filtering by delivery method in a sidebar.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current filter settings.
 * @param {Function} props.setFilterDeliveryTypes - A function to set the delivery types filter.
 * @param {Function} props.dispatch - The Redux dispatch function.
 * @param {boolean} props.isMobile - A flag indicating whether the device is mobile.
 */

export default function FilterSideBrand({
  itemsFilter,
  setFilterDeliveryTypes,
  dispatch,
  isMobile
}) {


  // const [isOpen, setIsOpen] = useState(!isMobile ? (itemsFilter?.deliveryTypes.length > 0) : !isMobile)
  
  // Get the current state of the payment filter
  const isOpen = useSelector(state => state.user.filterState.payment)
  // Function to set the state of the payment filter
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'payment', value }))
  }

  return (
    <div className='filter-item'>
      {/* <div className='filter-item-header'>
        <div className='filter-item-header-title'>
          <h4 onClick={() => setIsOpen(!isOpen)} className='cursor-pointer'>Payment/Delivery</h4>
        </div>
        {isOpen
          ? <AiOutlineUp size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />
          : <AiOutlineDown size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />}
      </div> */}
      <FilterSideSectionHeader
        title='Pick-Up/Reserve'
        isOpen={isOpen}
        onClickTitle={() => setIsOpen(!isOpen)}
        isHighlight={itemsFilter?.deliveryTypes.length > 0}
      />
      <div className='filter-item-list' style={{ display: isOpen ? 'flex' : 'none' }}>
        {deliveryMethod?.map((item, index) => (
          <div className='filter-checkbox-item' key={index}>
            <label>
              <input
                value={item.value}
                type='checkbox'
                className={item.available ? 'custom-checkbox' : 'custom-disabled-checkbox cursor-not-allowed'}
                checked={itemsFilter?.deliveryTypes.includes(item.value)}
                disabled={!item.available}
                onChange={e => {
                  dispatch(
                    setFilterDeliveryTypes({
                      name: item.value,
                      checked: e.target.checked,
                    }),
                  )
                }}
              />
              <span className='cursor-not-allowed truncate'>{item.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
