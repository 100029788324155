/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * RetailHero is a functional React component that displays a hero section for retail users.
 * It includes a message about the importance of online presence for brick and mortar stores and a call to action.
 * The RetailButtons component is used for user interactions.
 *
 * @author Yang Ming
 * @version February 7th, 2024
 */

import React from 'react'

import RetailButtons from './RetailButtons'

/**
 * Displays a hero section for retail users.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.setIsOpen - Function to set the state of the component's open status.
 * @param {function} props.setIsOpenForm - Function to set the state of the form's open status.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function RetailHero({
  isMobile,
  setIsOpen,
  setIsOpenForm
}) {
  return (
    <div className='bg-[#202020] w-full h-fit'>
      <div className='pt-[60px] w-full xl:w-[1024px] 2xl:w-[1380px] smsize:w-full m-auto flex items-center justify-center smsize:gap-[20px] smsize:pt-[20px] smsize:flex-col smsize:p-0 smsize:mb-10 flex-col phone:flex-row'>
        <div className='pb-[80px] smsize:pb-0 flex flex-col w-[700px] 2xl:w-fit smsize:w-fit smsize:gap-[20px] smsize:px-3'>
          <div className='flex w-full flex-col gap-5 smsize:gap-5 text-[#fff] p-6 text-left leading-tight xl:p-8'>
            <div className='w-full text-[18px] phone:text-[30px] 2xl:text-[34px]'>
              Did you know that{' '}
              <strong className='text-[#DBE366]'>
                9 out of 10
              </strong>{' '}
              people start the shopping process online?
            </div>
            <div className='text-[20px] font-extralight 2xl:text-[30px]'>
              Will they find your store and your products?
            </div>
            <div className='text-[16px] font-light phone:text-[20px] xl:text-[20px] 2xl:whitespace-nowrap smsize:whitespace-normal'>
              fitzba is levelling the digital playing field for bricks & mortar stores.
            </div>
            <RetailButtons
              setIsOpen={setIsOpen}
              setIsOpenForm={setIsOpenForm}
              gap={
                isMobile ? '20px' : '60px'
              }
              isLeft={true}
              extraClass='mt-5'
            />
          </div>
        </div>
        <div className='flex items-end justify-center min-w-fit'>
          <img width={700} src='/images/retail/retail-hero.webp' alt='Fitzba.com' />
        </div>
      </div>
    </div>
  )
}