/**
 *  Copyright Fitzba Technologies Inc. 2023
 *
 *  The content of the dropdown
 *
 * @author James Nguyen
 * @version May 09, 2023
 *
 */

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout as logoutAPI } from '@services/api.service'
import { logout } from '../reducers/userReducer'
import PWAPrompt from 'react-ios-pwa-prompt'

/**
 *
 * @param open      a boolean to determined if the content
 *                  should be display or not
 * @param userinfo  a json that contain the user info, we use this to
 *                  dertermined if a user is loged in or not
 * @returns Render all of the content of the dropdown onto the container
 */
export default function DropdownMenu({ open, setOpen, userinfo }) {
  const dispatch = useDispatch()
  const isBusiness = useSelector(state => state.user.isBusiness)
  const [isShowPWA, setIsShowPWA] = React.useState(false)

  const navigate = useNavigate()

  async function onClickLogout() {
    setOpen(false)
    await logoutAPI()
    dispatch(logout())
    navigate('/')
  }

  function onClickLink() {
    setOpen(false)
  }

  return (
    <div
      className={`absolute z-[99999] mt-5 w-[180px] rounded-md border border-black bg-white text-black shadow-xl ${open ? '' : 'hidden'
        }`}
    >
      {userinfo && (
        <Link to='/profile'
          className='block px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:rounded-t hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg'
        >
          User Profile
        </Link>
      )}
      {userinfo && isBusiness && (
        <>
          <a
            href={`${process.env.REACT_APP_APP_HOST}business`}
            target='_blank'
            rel='noreferrer'
            className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg '
          >
            Business Dashboard
          </a>
          <Link
            to='/inventoryImporter'
            className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg '
          >
            Inventory importer
          </Link>
        </>
      )}
      <Link
        to='/retail'
        onClick={onClickLink}
        className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg '
      >
        For Retailers
      </Link>
      <Link
        to='/about'
        onClick={onClickLink}
        className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg '
      >
        About Us
      </Link>
      <Link
        to='/contact'
        onClick={onClickLink}
        className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg '
      >
        Contact Us
      </Link>
      <Link
        to='/policy'
        onClick={onClickLink}
        className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg '
      >
        Privacy
      </Link>
      {userinfo ? (
        <Link
          className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:rounded-b hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg'
          onClick={onClickLogout}
        >
          Logout
        </Link>
      ) : (
        <Link
          to='/login'
          className='block border-t-[.5px] border-black px-4 py-3 text-sm capitalize text-gray-800 transition duration-300 ease-in-out hover:rounded-b hover:bg-fitzba-bright-gold-static hover:py-2 hover:text-lg'
        >
          Login
        </Link>
      )}

    </div>
  )
}
