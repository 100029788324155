/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartItemList is a functional React component that displays a list of items in the user's shopping cart.
 * It shows the name and address of the business for each item, the item details, and a button to reserve the item.
 * The reserve button is only shown if the business has a reservation email and the user is not on a mobile device.
 * If the user is on a mobile device, the reserve button is shown at the end of the list of items.
 *
 * @author James Nguyen, Yang Ming
 * @version January 15th, 2024
 */

import React from 'react'
import CartItemCard from './CartItemCard'
import { getDistance2 } from '@utils/Helper'

/**
 * Displays a list of items in the user's shopping cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.shoppingCart - The user's shopping cart.
 * @param {Object} props.location - The user's location.
 * @param {Function} props.handleUpdateCart - Function to update the quantity of an item in the cart.
 * @param {Function} props.handleDeleteCart - Function to delete an item from the cart.
 * @param {Function} props.setShowPopup - Function to show or hide the popup.
 * @param {Function} props.setSelectedStore - Function to set the selected store.
 * @param {boolean} props.isMobile - Whether the user is on a mobile device.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartItemList({
  shoppingCart,
  location,
  handleUpdateCart,
  handleDeleteCart,
  setShowPopup,
  setSelectedStore,
  isMobile,
}) {
  const cartData = shoppingCart?.data
  // const isMobile = useMediaQuery("max-width: 960px")
  const handleReserve = async name => {
    setSelectedStore(name)
    setShowPopup(true)
  }

  return (
    <div className='cart-content-list'>
      {cartData?.data?.map((item, index) => (
        <div className='cart-item' key={index}>
          <div className=' flex w-full flex-row items-center justify-between bg-[#f5f5f5] p-[10px]'>
            <div className='cart-item-store'>
              <h3>{item?.business?.name}</h3>
              <p>
                {item?.business?.address}, {item?.business?.city},{' '}
                {item?.business?.province} {item?.business?.postalCode},{' '}
                {getDistance2(location, {
                  lat: item?.business?.lat,
                  lng: item?.business?.lng,
                })}
              </p>
            </div>
            {item?.business?.reservationEmail && !isMobile && (
              <div
                onClick={() => {
                  handleReserve(item?.business?.name)
                }}
                className='flex h-[35px] w-[140px] cursor-pointer items-center justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-[#e4b456] uppercase text-white hover:bg-white hover:text-fitzba-bright-gold-static '
              >
                Reserve
              </div>
            )}
          </div>
          <div className='cart-item-list'>
            {item?.carts?.map((item, index) => (
              <CartItemCard
                key={index}
                item={item}
                handleUpdateCart={handleUpdateCart}
                handleDeleteCart={handleDeleteCart}
              />
            ))}
          </div>
          <div className='flex w-full justify-end'>
            {
              // item?.business?.membership?.type !== 'startz' &&
              item?.business?.reservationEmail && isMobile && (
                <div
                  onClick={() => {
                    handleReserve(item?.business?.name)
                  }}
                  className='flex h-[35px] w-[140px] cursor-pointer items-center justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-[#e4b456] uppercase text-white hover:bg-white hover:text-fitzba-bright-gold-static '
                >
                  Reserve
                </div>
              )
            }
          </div>
        </div>
      ))}
    </div>
  )
}
