export const Config = {
    // The name of the application
    appName: process.env.REACT_APP_APP_NAME || 'Shop real stores near you - Fitzba',
    // the host of website
    appHost: process.env.REACT_APP_APP_HOST || 'http://localhost:3000/',
    // The api base url
    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:4001',
    // The api base url
    // the prefix of api
    apiPrefix: process.env.REACT_APP_API_PREFIX || '/api/f',
    // the prefix of api
    // connect api key, most important
    apiKey: process.env.REACT_APP_API_KEY || '24ea1031-5821-488b-9b95-a1d9896d929d',
    // is debug mode
    debug: process.env.REACT_APP_DEBUG || false,
    //google sso client id
    googleSSOClientId: process.env.GOOGLE_SSO_CLIENT_ID || '220056909680-s28v3nnh5j4srhqh2k99lm6an8s2u9qa.apps.googleusercontent.com',
    // google map api key
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY || 'AIzaSyAilbdX42Ocz9avCYywCMJLRCwOtQu3jl8',
    gogoleRecaptchaSiteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '6Ldni8YkAAAAAHz1ipSYHs6QrSlTIMiBEoZ5BfEp',
    // googlePlacesApiKey: process.env.REACT_APP_GOOGLE_PLACES_APIKEY || 'AIzaSyC6KT8vFR4cvI99pJDPHKC5m6xYC0NWo_w',
    // social media links
    socialMedia: {
        facebook: process.env.REACT_APP_FACEBOOK || 'https://www.facebook.com/TheFitzba',
        instagram: process.env.REACT_APP_INSTAGRAM || 'https://www.instagram.com/thefitzba/',
        linkedin: process.env.REACT_APP_LINKEDIN || 'https://www.linkedin.com/company/fitzba-technologies-inc/',
    },
    // calendar link
    calendlyUrl: process.env.REACT_APP_CALENDLY_URL || 'https://calendly.com/alana-fitzba/intro-call',
}