/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfile.js implements the store profile. It handles fetching the store data, 
 * displaying the store information, and user interactions with the store profile.
 * 
 * This component uses several sub-components to build the store profile:
 * - StoreProfileBrand
 * - StoreProfileCategory
 * - StoreProfileDesc
 * - StoreProfileInfo
 * - StoreProfileSearchBar
 * - StoreProfileLeft
 * - StoreProfileMobile
 * 
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import { initItemsFilter } from '@reducers/contentReducer'
// import { Avatar } from 'antd'
import { getStoreProfile } from '@services/api.service'
import { useQuery } from '@tanstack/react-query'
import {
  setFilterBrandProfile,
  setFilterStoreOnlyOne,
  setFilterCategoryProfile,
} from '@reducers/contentReducer'
import StoreProfileBrand from './StoreProfileBrand'
import StoreProfileCategory from './StoreProfileCategory'
import StoreProfileDesc from './StoreProfileDesc'
import StoreProfileInfo from './StoreProfileInfo'
import StoreProfileSearchBar from './StoreProfileSearchBar'
import Loading from '@components/Loading'
import StoreProfileLeft from './StoreProfileLeft'
import StoreProfileMobile from './StoreProfileMobile'
import LoadingSection from '@components/LoadingSection'
import { Config } from '@Config'
import { Helmet } from 'react-helmet-async'
/**
 * Store profile component 
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.item - The store item.
 * @param {Function} props.setShowAllStores - The function to set the visibility of all stores.
 */
export default function StoreProfile({ item }) {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.content.isMobile)
  const [showInfo, setShowInfo] = useState(true)
  const [showDepartments, setShowDepartments] = useState(false)
  const location = useSelector(state => state.user.location)
  const itemsFilter = useSelector(state => state.content.itemsFilter)

  // const [business, setBusiness] = useState(null)

  const {
    isPending,
    error,
    data: business,
  } = useQuery({
    queryKey: ['storeProfile', item._id, location?.lat, location?.lng],
    queryFn: () => getStoreProfile(item._id, {
      lat: location?.lat,
      lng: location?.lng,
    }),
  })

  if (isPending) return <LoadingSection text='Loading Store Profile' height={'200px'} />
  if (error) {
    console.error('Error loading store profile', error)
    return 'Loading Store Profile Error'
  }
  
  const handleClickStore = () => {
    // setShowAllStores(false)
    dispatch(setFilterStoreOnlyOne(item._id))
  }

  if (!business)
    return <Loading />

  if (isMobile)
    return (
      <StoreProfileMobile
        itemsFilter={itemsFilter}
        setFilterCategoryProfile={setFilterCategoryProfile}
        setFilterBrandProfile={setFilterBrandProfile}
        dispatch={dispatch}
        business={business}
        handleClickStore={handleClickStore}
      />
    )

  return (
    <div className='flex min-h-[200px] w-full flex-row gap-10 pr-12'>
      <Helmet>
        <title>
          {business?.name} - {Config.appName}
        </title>
      </Helmet>
      <StoreProfileLeft
        isMobile={isMobile}
        business={business}
        handleClickStore={handleClickStore}
      />
      <div className='flex w-full flex-col '>
        <div className={`flex flex-row items-center justify-between`}>
          <h4 className='text-[48px] font-medium'>{business?.name}</h4>
          <div className='flex justify-center items-center gap-2'>
            <StoreProfileSearchBar
              business={business}
              itemsFilter={itemsFilter}
            />
            {(business?.brands?.length > 0 ||
              business?.categories?.length > 0) && (
                <button
                  className='flex h-fit justify-center whitespace-nowrap rounded-full border border-black px-[16px] py-[7px] text-[13px] hover:text-black hover:bg-[#D9D9D9] hover:scale-105 transition-transform duration-150 ease-in-out'
                  onClick={() => {
                    setShowInfo(!showInfo)
                    setShowDepartments(!showDepartments)
                  }}
                >
                  {showInfo ? 'View Departments' : 'View Store Info'}
                </button>
              )}
          </div>
        </div>
        {showInfo ? (
          <div
            className={`flex h-fit flex-row gap-5 pb-1`}
          >
            <StoreProfileDesc
              description={business?.description}
            />
            <StoreProfileInfo
              business={business}
            />
            {!business?.description && <div className='flex-1' />}
          </div>
        ) : (
          <div className='flex h-fit min-h-[200px] flex-row gap-5 pb-1'>
            <StoreProfileBrand
              dispatch={dispatch}
              setFilterBrandProfile={setFilterBrandProfile}
              itemsFilter={itemsFilter}
              brands={business?.brands}
            />
            <StoreProfileCategory
              dispatch={dispatch}
              setFilterCategoryProfile={setFilterCategoryProfile}
              itemsFilter={itemsFilter}
              categories={business?.categories}
            />
            {((business?.brands?.length > 0 &&
              business?.categories?.length === 0) ||
              (business?.brands?.length === 0 &&
                business?.categories?.length > 0)) && (
                <div className='flex-1' />
              )}
          </div>
        )}
      </div>
    </div>
  )
}
