/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * contentReducer.ts, it handles content actions, including init content list
 * @author Dean Yang
 * @version Feb 17, 2023
 */
import { createSlice, current } from '@reduxjs/toolkit'

const getInitItemsFilter = {
  currentPage: 1,
  perPage: 20,
  totalPage: 1,
  title: '',
  categories: [],   // selected categories
  sortBy: -1,
  priceMin: -1,
  priceMax: -1,
  stores: [],     // selected stores
  brands: [],    // selected brands
  distance: -1,
  onlyOneStore: 0,
  searchInStore: 0,
  deliveryTypes: [],
  qrcode: 0,
  code: '',
  condition: [],
}

const getInitReviewContent = {
  data: [],
  status: 'success',
  total: 0,
  rate: 0,
}

const getInitItemsList = {
  data: [],
  status: 'success',
  total: 0,
  currentPage: 1,
  perPage: 20,
  totalPage: 1,
}

const getInitFilterList = {
  keywords: null,
  updatedType: '',    // all, brand, store, category
  categories: [],
  stores: [],
  brands: [],
}

const getInitStoresList = {
  data: [],
  status: 'success',
  total: 0,
  currentPage: 1,
  perPage: 20,
  totalPage: 1,
}

const getInitFaqList = {
  data: [],
  status: 'success',
  total: 0,
  currentPage: 1,
  perPage: 20,
  totalPage: 1,
}

const getInitBlogList = {
  data: [],
  status: 'success',
  total: 0,
  currentPage: 1,
  perPage: 10,
  totalPage: 1,
  currentId: null,
  currentBlog: null,
  filterTypes: [],
}

const getInitBrandList = {
  data: [],
  status: 'success',
  total: 0,
  currentPage: 1,
  perPage: 30,
  totalPage: 1,
}

const getInitBrandFilter = {
  title: '',
  stores: [],
  categories: [],
  currentPage: 1,
}

const getInitStoreProfileFilter = {
  title: '',
  brands: [],
  categories: [],
  currentPage: 1,
  priceMin: -1,
  priceMax: -1,
  sortBy: -1,
  condition: [],
  deliveryTypes: [],
  isOnSale: false
}

export const SORTBY_CODE = {
  RELEVANCE: -1,
  NEAREST_TO_FARTHEST: 0,
  LOW_TO_HIGH: 1,
  HIGH_TO_LOW: 2,
  UNWANTED: 3,
}

export const initialState = {
  isMobile: false,
  isSearching: false,
  isLoadingSeeMore: false,
  gotoElement: null,
  gotoScrollY: 0,
  saveScrollY: 0,
  searchInput: '',
  contents: [],
  searchHistory: [],
  reviewContent: getInitReviewContent,
  categoryList: [],
  categoryListExisted: [],
  categoryListExpanded: [],
  businessTypeList: [],
  currentItem: null,
  similarList: [],
  isReloadItemsList: true,
  isReloadCategories: true,
  isClearAllFilters: false,
  itemsFilter: getInitItemsFilter,
  preItemsFilter: getInitItemsFilter,
  itemsList: getInitItemsList,
  storesList: getInitStoresList,
  storesListExisted: [],
  faqlist: getInitFaqList,
  filterList: getInitFilterList,
  recommendedList: [],
  blogList: getInitBlogList,
  brandList: getInitBrandList,
  brandFilter: getInitBrandFilter,
  newStoreList: {
    data: [],
    status: 'success',
    totalPage: 1,
    currentPage: 1,
  },
  newStoreFilter: {
    title: '',
    currentPage: 1,
    categories: [],
    distance: -1,
  },
  newCategoryList: [],
  storeProfileFilter: getInitStoreProfileFilter,
  storeProfileFilterChanged: false,
  storeProfileList: {
    data: [],
    status: 'success',
    total: 0,
    currentPage: 1,
    perPage: 20,
    totalPage: 1,
  },
}

const contentSlice = createSlice({
  name: 'content',
  initialState: initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    },
    setSearchHistory: (state, action) => {
      if (action.payload === null) {
        state.searchHistory = []
        localStorage.removeItem('searchHistory')
        return
      }
      // load an array
      if (typeof action.payload === 'string') {
        const arr = action.payload.split(',')
        // remove empty string and duplicates
        state.searchHistory = [...new Set(arr.filter(item => item !== ''))]
        // state.searchHistory = action.payload.split(',')
        return
      }
      if (action.payload?.type === 'add') {
        if (!state.searchHistory.includes(action.payload.value))
          state.searchHistory.push(action.payload.value)
      } else if (action.payload?.type === 'remove') {
        if (state.searchHistory.includes(action.payload.value))
          state.searchHistory = state.searchHistory.filter(
            item => item !== action.payload.value,
          )
      }
      console.log('setSearchHistory', state.searchHistory)
      // save the first 10 keywords to local storage
      localStorage.setItem('searchHistory', state.searchHistory?.slice(0, 10)?.join(','))
    },
    setSearchInput: (state, action) => {
      state.searchInput = action.payload
    },
    setGotoElement: (state, action) => {
      state.gotoElement = action.payload
    },
    setGotoScrollY: (state, action) => {
      state.gotoScrollY = action.payload
    },
    setPreItemsFilter: (state, action) => {
      state.preItemsFilter = action.payload
    },
    setItemsFilter: (state, action) => {
      state.itemsFilter = action.payload
    },
    setSaveScrollY: (state, action) => {
      state.saveScrollY = action.payload
    },
    initContentList: (state, action) => {
      state.contents = action.payload
    },
    setIsLoadingSeeMore: (state, action) => {
      state.isLoadingSeeMore = action.payload
    },
    initCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    initBusinessTypeList: (state, action) => {
      state.businessTypeList = action.payload
    },
    initItemsList: (state, action) => {
      state.itemsList = getInitItemsList
    },
    setIsReloadItemsList: (state, action) => {
      state.isReloadItemsList = action.payload
    },
    setItemsList: (state, action) => {
      state.itemsList = action.payload
    },
    initStoresList: (state, action) => {
      state.storesList = getInitStoresList
    },
    setStoresList: (state, action) => {
      state.storesList = action.payload
    },
    setStoresListExisted: (state, action) => {
      state.storesListExisted = action.payload
    },
    initItemsFilter: (state, action) => {
      state.itemsFilter = action.payload ? action.payload : getInitItemsFilter
      state.searchInput = action.payload ? action.payload?.title : ''
      state.categoryListExpanded = []
      state.isClearAllFilters = true
      state.isReloadItemsList = true
      state.isReloadCategories = true
    },
    setSearchTitle: (state, action) => {
      // update May 08, 2024
      // state.itemsFilter.currentPage = 1
      // state.itemsFilter.title = action.payload
      state.itemsFilter = {
        ...getInitItemsFilter,
        title: action.payload
      }
      state.searchInput = action.payload
      state.categoryListExpanded = []
      state.isReloadItemsList = true
      state.isReloadCategories = true
    },
    setStoreProfileSearch: (state, action) => {
      state.itemsFilter.currentPage = 1
      state.itemsFilter.title = action.payload.title
      state.itemsFilter.stores = [action.payload.store]
      // spread the search behavior globally or search in store
      state.itemsFilter.searchInStore = 1
      state.itemsFilter.onlyOneStore = 1
      // state.itemsFilter.categories = []
      // state.itemsFilter.brands = []
      // state.isReloadItemsList = true
    },
    setFilterPage: (state, action) => {
      state.itemsFilter.currentPage = action.payload
      state.isReloadItemsList = true
    },
    setFaqList: (state, action) => {
      state.faqlist = action.payload
    },
    initBlogList: (state, action) => {
      state.blogList = getInitBlogList
    },
    setBlogList: (state, action) => {
      state.blogList = {
        ...state.blogList,
        ...action.payload
      }
    },
    setBlogFilter: (state, action) => {
      if (action.payload?.currentPage)
        state.blogList.currentPage = action.payload.currentPage
      if (action.payload?.action) {
        if (action.payload.action === 'all') {
          state.blogList.filterTypes = []
        } else if (action.payload.action === 'add' && !state.blogList.filterTypes.includes(action.payload.value)) {
          state.blogList.filterTypes.push(action.payload.value)
        } else if (action.payload.action === 'remove') {
          state.blogList.filterTypes = state.blogList.filterTypes.filter(
            type => type !== action.payload.value,
          )
        }
      }
    },
    setBlogCurrent: (state, action) => {
      state.blogList.currentBlog = action.payload
    },
    setReviewList: (state, action) => {
      state.reviewContent.data = action.payload.data
      state.reviewContent.total = action.payload.total
      state.reviewContent.rate = action.payload.rate
    },
    setFilterSortBy: (state, action) => {
      state.itemsFilter.sortBy = action.payload
      state.isReloadItemsList = true
    },
    setFilterCode: (state, action) => {
      state.itemsFilter.code = action.payload
      state.isReloadItemsList = true
    },
    setFilterCondition: (state, action) => {
      if (action.payload == null) {
        state.itemsFilter.condition = []
        state.isReloadItemsList = true
        return
      }
      if (action.payload.checked) {
        // check if it's already in the list
        if (!state.itemsFilter.condition.includes(action.payload.name))
          state.itemsFilter.condition.push(action.payload.name)
      } else {
        state.itemsFilter.condition = state.itemsFilter.condition.filter(
          type => type !== action.payload.name,
        )
      }
      // if (state.itemsFilter.condition.length === 2) {
      //   state.itemsFilter.condition = []
      // } 
      state.isReloadItemsList = true
    },
    setFilterDistance: (state, action) => {
      state.itemsFilter.distance = action.payload
      state.isReloadItemsList = true
    },
    setFilterPrice: (state, action) => {
      state.itemsFilter.priceMin = action.payload.priceMin
      state.itemsFilter.priceMax = action.payload.priceMax
      state.isReloadItemsList = true
    },
    setFilterBrand: (state, action) => {
      if (action.payload == null) {
        state.itemsFilter.brands = []
        state.isReloadItemsList = true
        state.filterList.updatedType = 'all'
        return
      }
      const ids = Array.isArray(action.payload.id) ? action.payload.id : [action.payload.id]
      if (action.payload.checked) {
        // state.itemsFilter.brands.push(action.payload.id)
        // state.itemsFilter.brands = [...state.itemsFilter.brands, ...ids]
        state.itemsFilter.brands = [...new Set([...state.itemsFilter.brands, ...ids])]
      } else {
        // state.itemsFilter.brands = state.itemsFilter.brands.filter(
        //   brand => brand !== action.payload.id,
        // )
        state.itemsFilter.brands = state.itemsFilter.brands.filter(
          brand => !ids.includes(brand),
        )
      }
      if (state.itemsFilter.brands.length === 0) {
        state.filterList.updatedType = 'all'
      } else {
        state.filterList.updatedType = 'brand'
      }
      state.isReloadItemsList = true
    },
    setFilterBrandProfile: (state, action) => {
      const existingIndex = state.itemsFilter.brands.findIndex(item => item === action.payload._id)
      if (existingIndex === -1) {
        state.itemsFilter.brands.push(action.payload._id)
      } else {
        state.itemsFilter.brands = state.itemsFilter.brands.filter(
          brand => brand !== action.payload._id,
        )
      }
      state.isReloadItemsList = true
    },
    setFilterCategoryProfile: (state, action) => {
      const existingIndex = state.itemsFilter.categories.findIndex(item => item === action.payload._id)
      if (existingIndex === -1) {
        state.itemsFilter.categories.push(action.payload._id)
      } else {
        state.itemsFilter.categories = state.itemsFilter.categories.filter(
          id => id !== action.payload._id,
        )
      }
      state.isReloadItemsList = true
    },
    setFilterDeliveryTypes: (state, action) => {
      if (action.payload == null) {
        state.itemsFilter.deliveryTypes = []
        state.isReloadItemsList = true
        return
      }
      if (action.payload.checked) {
        state.itemsFilter.deliveryTypes.push(action.payload.name)
      } else {
        state.itemsFilter.deliveryTypes = state.itemsFilter.deliveryTypes.filter(
          type => type !== action.payload.name,
        )
      }
      state.isReloadItemsList = true
    },
    setFilterCategory: (state, action) => {
      if (action.payload == null) {
        state.itemsFilter.categories = []
        state.isReloadItemsList = true
        return
      }
      if (action.payload.checked) {
        state.itemsFilter.categories.push(action.payload.id)
      } else {
        state.itemsFilter.categories = state.itemsFilter.categories.filter(
          id => id !== action.payload.id,
        )
      }
      state.isReloadItemsList = true
    },
    // new one
    setFilterCategoryList: (state, action) => {
      state.itemsFilter.categories = action.payload
      state.isReloadItemsList = true
      state.filterList.updatedType = 'category'
    },
    setFilterExpandedCategories: (state, action) => {
      state.categoryListExpanded = action.payload
    },
    setFilterCategoryNew: (state, action) => {
      if (action.payload == null) {
        state.itemsFilter.categories = []
        return
      }
      if (action.payload.checked) {
        if (!state.itemsFilter.categories.includes(action.payload.id))
          state.itemsFilter.categories.push(action.payload.id)
      } else {
        if (state.itemsFilter.categories.length === 0) {
          const categories = state.categoryList?.data?.filter(item => item.id !== action.payload.id)
          state.itemsFilter.categories = categories.map(category => category.id)
        } else {
          state.itemsFilter.categories = state.itemsFilter.categories.filter(
            id => id !== action.payload.id,
          )
        }
      }
      state.filterList.updatedType = 'category'
    },
    setFilterStore: (state, action) => {
      console.log('setFilterStore', action.payload)
      if (action.payload == null) {
        state.itemsFilter.stores = []
        state.isReloadItemsList = true
        state.isReloadCategories = true
        state.filterList.updatedType = 'all'
        return
      }
      const ids = Array.isArray(action.payload.id) ? action.payload.id : [action.payload.id]
      if (action.payload.checked) {
        state.itemsFilter.stores = [...new Set([...state.itemsFilter.stores, ...ids])]
      } else {
        state.itemsFilter.stores = state.itemsFilter.stores.filter(
          store => !ids.includes(store),
        )
      }
      state.itemsFilter.onlyOneStore = state.itemsFilter.stores.length === 1 ? 1 : 0
      state.isReloadItemsList = true
      state.isReloadCategories = true
      state.filterList.updatedType = 'store'
    },
    setFilterStoreNew: (state, action) => {
      if (action.payload == null) {
        state.itemsFilter.stores = []
        return
      }
      if (action.payload.checked) {
        if (!state.itemsFilter.stores.includes(action.payload.id))
          state.itemsFilter.stores.push(action.payload.id)
      } else {
        if (state.itemsFilter.stores.length === 0) {
          const stores = state.storesList?.data?.filter(item => item.id !== action.payload.id)
          state.itemsFilter.stores = stores.map(store => store.id)
        } else {
          state.itemsFilter.stores = state.itemsFilter.stores.filter(
            id => id !== action.payload.id,
          )
        }
      }
      if (state.itemsFilter.stores.length >= 2) {
        state.itemsFilter.onlyOneStore = 0
      }
    },
    setFilterStoreOnlyOne: (state, action) => {
      state.itemsFilter.stores = [action.payload]
      state.itemsFilter.onlyOneStore = 1
      // state.itemsFilter.code = ''
      // state.itemsFilter.title = ''
      state.isReloadItemsList = true
      state.isReloadCategories = true
    },
    setOnlyOneStore: (state, action) => {
      state.itemsFilter.onlyOneStore = action.payload
      state.isReloadItemsList = true

    },
    setFilterStoreAndKeywords: (state, action) => {
      if (action.payload?.store && !state.itemsFilter.stores.includes(action.payload.store)) {
        state.itemsFilter.stores = [action.payload.store]
        state.itemsFilter.onlyOneStore = 1
      }
      if (action.payload?.keywords && state.itemsFilter.title !== action.payload.keywords) {
        state.itemsFilter.title = action.payload.keywords
      }
      state.isReloadItemsList = true
    },
    setCurrentItem: (state, action) => {
      state.currentItem = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
      state.isReloadCategories = false
    },
    setCategoryListExisted: (state, action) => {
      state.categoryListExisted = action.payload
      state.isReloadCategories = true
    },
    setFilterSideBrands: (state, action) => {
      state.filterList.brands = action.payload
    },
    setFilterSideStores: (state, action) => {
      state.filterList.stores = action.payload
    },
    setFilterList: (state, action) => {
      state.filterList.keywords = action.payload.keywords
      state.filterList.brands = action.payload.brands
      state.filterList.stores = action.payload.stores
      state.filterList.categories = action.payload.categories
      state.filterList.updatedType = 'all'
      state.isClearAllFilters = false
      // if (state.isClearAllFilters ||
      //   action.payload?.keywords !== state.filterList.keywords ||
      //   state.filterList.updatedType === 'all') {
      //   state.filterList.keywords = action.payload.keywords
      //   state.filterList.brands = action.payload.brands
      //   state.filterList.stores = action.payload.stores
      //   if (action.payload?.categories)
      //     state.filterList.categories = action.payload.categories
      //   state.isClearAllFilters = false
      // }
      // if (state.filterList.updatedType === 'store') {
      //   state.filterList.brands = action.payload.brands
      //   if (action.payload?.categories)
      //     state.filterList.categories = action.payload.categories
      // }
      // if (state.filterList.updatedType === 'brand') {
      //   state.filterList.stores = action.payload.stores
      //   if (action.payload?.categories)
      //     state.filterList.categories = action.payload.categories
      // }
      // if (state.filterList.updatedType === 'category') {
      //   state.filterList.stores = action.payload.stores
      //   state.filterList.brands = action.payload.brands
      // }
    },
    setZearch: (state, action) => {
      state.zearchMethod = action.payload
    },
    setSimilar: (state, action) => {
      state.similarList = action.payload
    },
    setRecommendedList: (state, action) => {
      state.recommendedList = action.payload
    },
    setBrandList: (state, action) => {
      state.brandList = action.payload
    },
    setBrandFilter: (state, action) => {
      state.brandFilter = action.payload
    },
    setNewStoreList: (state, action) => {
      state.newStoreList = action.payload
    },
    setNewStoreFilter: (state, action) => {
      state.newStoreFilter = action.payload
    },
    setNewCategoryList: (state, action) => {
      state.newCategoryList = action.payload
    },
    setStoreProfileFilter: (state, action) => {
      state.storeProfileFilter = action.payload
      state.storeProfileFilterChanged = true
    },
    setStoreProfileList: (state, action) => {
      state.storeProfileList = action.payload
      state.storeProfileFilterChanged = false
    },
    setStoreProfileFilterChanged: (state, action) => {
      state.storeProfileFilterChanged = action.payload
    }
  },
})

export const {
  setIsMobile,
  setSearchHistory,
  setIsReloadItemsList,
  setSearchInput,
  setStoreProfileSearch,
  setFilterDistance,
  setGotoElement,
  setGotoScrollY,
  setSaveScrollY,
  initContentList,
  initCategoryList,
  setItemsList,
  setSearchTitle,
  initItemsFilter,
  setFilterPage,
  initBusinessTypeList,
  setFilterSortBy,
  setFilterCondition,
  setFilterPrice,
  initItemsList,
  setFilterBrand,
  setFilterBrandProfile,
  setFilterCategoryProfile,
  setFilterDeliveryTypes,
  setFilterCategory,
  setFilterStore,
  setFilterCategoryNew,
  setFilterCode,
  setFilterStoreNew,
  setFilterStoreOnlyOne,
  setCurrentItem,
  setStoresList,
  setStoresListExisted,
  initStoresList,
  setZearch,
  setFaqList,
  setBlogList,
  setBlogFilter,
  setBlogCurrent,
  setSimilar,
  setRecommendedList,
  setReviewList,
  setCategoryList,
  setCategoryListExisted,
  setFilterSideBrands,
  setFilterSideStores,
  setFilterCategoryList,
  setFilterExpandedCategories,
  setFilterStoreAndKeywords,
  setFilterList,
  setPreItemsFilter,
  setItemsFilter,
  setIsLoadingSeeMore,
  setBrandList,
  setBrandFilter,
  setNewStoreList,
  setNewStoreFilter,
  setNewCategoryList,
  setStoreProfileFilter,
  setStoreProfileList,
  setStoreProfileFilterChanged
} = contentSlice.actions

export default contentSlice.reducer
