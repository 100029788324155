/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * api.reservations.service contains functions for making API calls related to reservations.
 * 
 * @author James Nguyen, Yang Ming
 * @version November 17th, 2023
 */

import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
  baseURL: Config.apiUrl + Config.apiPrefix,
  timeout: 60 * 1e3,
  // 5000
})

httpClient.interceptors.request.use(config => {
  // Perform logic before sending request
  config.headers['authorization'] =
    `${Config.apiKey} ` + localStorage.getItem('token')
  return config
})

/**
 * Makes a reservation for a cart.
 *
 * @param {Object} params - The parameters for the reservation.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function makeCartReservation(params) {
  // console.log(itemId, count)
  try {
    const response = await httpClient.post(`/reserveCart`, params)
    return response.data
  } catch (error) {
    throw error.response
  }
}

/**
 * Answers a reservation.
 *
 * @param {Object} params - The parameters for the reservation.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function answerReservation(params) {
  // console.log(itemId, count)
  try {
    const response = await httpClient.post(`/reserve/answer`, params)
    return response.data
  } catch (error) {
    throw error.response
  }
}

export async function getReservation(id) {
  // console.log(itemId, count)
  try {
    const response = await httpClient.get(`/reserve/${id}`)
    return response.data
  } catch (error) {
    throw error.response
  }
}
