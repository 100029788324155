/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartReservation is a functional React component that displays a form for the user to reserve items in their cart.
 * It shows the items in the cart, their quantities, and their prices.
 * It also provides input fields for the user to enter their name and email.
 * After the user submits the form, it shows a confirmation message.
 *
 * @author James Nguyen, Yang Ming
 * @version January 15th, 2024
 */

import React, { useState, useEffect } from 'react'
import { message, Form } from 'antd'
import { makeCartReservation } from '@services/api.reservation.service'
import CartReserveCompleted from './CartReserveCompleted'
import CartReserveForm from './CartReserveForm'
import CommonModal from '@components/CommonModal'

/**
 * Displays a form for the user to reserve items in their cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.userinfo - The user's information.
 * @param {boolean} props.isMobile - Whether the user is on a mobile device.
 * @param {Object} props.cartData - The data of the user's cart.
 * @param {Function} props.setShowPopup - Function to show or hide the popup.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartReservation({
  userinfo,
  isMobile,
  cartData,
  showPopup,
  setShowPopup,
}) {
  const [form] = Form.useForm()
  const [showFinish, setShowFinish] = useState(false)

  useEffect(() => {
    if (userinfo && form) {
      form.setFieldValues({
        userEmail: userinfo?.username,
        username: userinfo?.firstName,
      })
    }
  }, [form, userinfo])

  const handleSubmit = async value => {
    try {
      await makeCartReservation({
        businessId: cartData?._id,
        items: cartData?.carts?.filter(item => item.isSelected),
        ...value,
      })
      // console.log(cartData)
      setShowFinish(true)
      // console.log(value)
      // message.success('Your reservation has been made')
    } catch (error) {
      message.error('Something went wrong: ' + error?.data?.message)
    }
  }

  return (
    <CommonModal
      isOpen={showPopup}
      setIsOpen={setShowPopup}
      width={'600px'}
      children={
        <div className='relative flex h-fit w-full flex-col items-center justify-center rounded-3xl bg-fitzba-white-static font-lexend drop-shadow-2xl sm:w-[500px] lg:w-[600px]'>
          <div
            className={`flex h-[120px] w-full items-center justify-center rounded-t-3xl bg-black`}
          >
            <img
              src='/fitzba-ICON-WORD-WHITE.png'
              alt='logo'
              className='h-16 '
            />
            <div className='absolute top-4 right-5 text-xl text-[#a6a6a6]'>
              <button
                onClick={() => {
                  setShowPopup(false)
                  setShowFinish(false)
                }}
              >
                x
              </button>
            </div>
          </div>

          <div className='w-full overflow-y-scroll px-[10px]'>
            {showFinish ? (
              <CartReserveCompleted />
            ) : (
              <CartReserveForm
                form={form}
                cartData={cartData}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      }
    />
  )
}
