/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Registerv2 is a functional React component that renders a multi-step registration
 * form to collect user and business information for account creation.
 *
 *  *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import { registerConsumer, registerBusiness } from '@services/api.service'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { setBusiness, setUserinfo } from '../../reducers/userReducer'
import RegisterConsumer from './components/RegisterConsumerNew'
import { login, registerConsumerV3 } from '../../services/api.service'
import LoginLeftNew from './components/LoginLeftNew'
import SSOLogin from './components/SSOLogin'
import QuestionStep1 from './components/QuestionStep1'
import QuestionStep2 from './components/QuestionStep2'

export const REGISTER_STEPS = {
  REGISTER: 0,
  QUESTION1: 1,
  QUESTION2: 2,
  FINISH: 3
}

export default function RegisterV4() {
  const [current, setCurrent] = useState(REGISTER_STEPS.REGISTER)
  const [formData, setFormData] = useState({})
  // const [questionData, setQuestionData] = useState({})
  const isMobile = useSelector(state => state.content.isMobile)
  const dispatch = useDispatch()

  async function handleSubmit(values) {
    try {
      await registerConsumerV3({
        username: formData.username || values.username,
        firstName: formData.firstName || values.firstName,
        lastName: formData.lastName || values.lastName,
        password: formData.password || values.password,
      })
      const tmp_id = localStorage.getItem('tmp_id')
      const loginRes = await login({
        username: formData.username || values.username,
        password: formData.password || values.password,
        tmp_id,
      })
      dispatch(setUserinfo(loginRes.data))
      if (tmp_id) {
        localStorage.removeItem('tmp_id')
      }
      setCurrent(REGISTER_STEPS.FINISH)
    } catch (err) {
      console.log('err', err)
      message.error(err.data?.message || 'Register failed, please try again')
    }
  }

  // useCalendlyEventListener({
  //   onEventScheduled: () => next(),
  // });

  return (
    // <div className=' flex  h-max tall:h-[100vh]'>
    //   <div className='hidden flex-1 flex-col items-center justify-center bg-fitzba-off-black-static xl:flex'>
    <div className='flex h-max tall:h-[100vh]'>
      <LoginLeftNew />
      <div className='flex-[1] m-auto w-full h-[100vh] bg-white flex items-center justify-center'>
        <div className='flex min-h-[100vh] w-full items-center justify-center p-2'>
          <div className='m-0 w-full flex flex-col'>
            <div className='w-full hidden smsize:flex smsize:mb-10 items-center justify-center'>
              <img className='text-center' width={240} src="/fitzba-ICON-WORD-BLACK.png" alt="Fitzba Logo" />
            </div>
            {current === REGISTER_STEPS.REGISTER && (
              <div className='w-full flex flex-col items-center justify-center'>
                <div className='flex flex-col gap-1 mb-3'>
                  <h3 className='text-[38px] smsize:text-[30px] font-medium mb-2'>
                    Hello!
                  </h3>
                  <p className='text-[16px] font-light'>
                    Looks like you don't have an account
                  </p>
                  <h4 className='text-[30px] font-light'>Tell us about yourself</h4>
                </div>
                <RegisterConsumer
                  formData={formData}
                  setFormData={setFormData}
                  handleSubmit={handleSubmit}
                />
                <div className='mt-5'>
                  <SSOLogin />
                </div>
              </div>
            )}
            {current === REGISTER_STEPS.QUESTION1 && (
              <QuestionStep1 current={current} setCurrent={setCurrent} isMobile={isMobile} />
            )}
            {current === REGISTER_STEPS.QUESTION2 && (
              <QuestionStep2 current={current} setCurrent={setCurrent} />
            )}
            {current === REGISTER_STEPS.FINISH && (
              <div className='mx-auto flex w-full max-w-[500px] flex-col items-center justify-center gap-7 text-black text-center'>
                <h4 className='text-[38px] smsize:text-[30px] font-medium text-black'>
                  Thank you for registering!
                </h4>
                <p className='text-[22px] font-light'>Check your email to verify your account</p>
                <Link
                  to='/search'
                  className='py-2 px-20 text-[15px] flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                >
                  START SEARCHING
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
